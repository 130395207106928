import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload, RequiredAuth, SenecaResponse, User } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');
import { SidebarService } from 'src/app/shared/services/sidebar.service';

const PROFILE_STI = [RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_MANAGERS, RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_RETAIL, RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_PLATFORM_MANAGER, RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_PROFESSIONAL, RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_TEAM_LEADER, RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_TEAM_LEADER_NO_ROLLING];
const PROFILE_NO_STI = [RequiredAuth.PERFORMANCECARE_MANAGER_OPERATIONS, RequiredAuth.PERFORMANCECARE_MANAGER_STAFF, RequiredAuth.PERFORMANCECARE_PLATFORM_MANAGER, RequiredAuth.PERFORMANCECARE_USER_OPERATIONS, RequiredAuth.PERFORMANCECARE_USER_STAFF];

@Component({
  selector: 'admin-process-setup-participant',
  templateUrl: './processSetupParticipant.component.html',
  styleUrls: ['./processSetupParticipant.component.scss']
})
export class ProcessSetupParticipantComponent implements OnDestroy {
  @Input() processData: any;

  runningYear$: Subscription;
  combinedSelected$: Subscription = new Subscription();
  runningYear: number = 0;
  loggedUser: any;

  isLoadingParticipantData: boolean = false;
  getProcessesData$: Subscription = new Subscription;
  participantData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]
  } = {
      fromRecord: 0,
      numRecords: 50,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 50,
      title: 50
    },
    {
      id: 100,
      title: 100
    },
    {
      id: 150,
      title: 150
    }
  ]
  selectedRows: { id: number, title: number };

  menuOptions: any[] = [];
  processToDelete: any;

  processYear: string = '';
  tabList: any[] = [];
  searchedText: string = '';
  stiModalUser: any;
  // tootlip filtri
  whoTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal014',
    title: '',
    text: ''
  };
  structureTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal015',
    title: '',
    text: ''
  };
  structureLevel3TooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal016',
    title: '',
    text: ''
  };
  jobTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal017',
    title: '',
    text: ''
  };
  stiFilterTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal018',
    title: '',
    text: ''
  };
  functionTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal019',
    title: '',
    text: ''
  };
  statusFilterTooltip: ApplicationModalMessage = {
    modalId: 'cal026',
    title: '',
    text: ''
  }
  genderFilterTooltipModal: ApplicationModalMessage = {
    modalId: 'cal021',
    title: '',
    text: ''
  }
  areaTooltipModal: ApplicationModalMessage = {
    modalId: 'cal023',
    title: '',
    text: ''
  }
  structureLevel5TooltipModal: ApplicationModalMessage = {
    modalId: 'cal024',
    title: '',
    text: ''
  }
  // fine tooltip filtri
  today = moment().format();

  confirmSTIModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  // modali inclusione / esclusione 
  resetIncludeUpload: boolean = false;
  resetExcludeUpload: boolean = false;
  includeFileObject: any;
  excludeFileObject: any;
  isDownloadingExcludeTrack: boolean = false;
  isDownloadingIncludeTrack: boolean = false;
  uploadIncludeTooltip: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  uploadExcludeTooltip: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  isLoadingFilters: boolean = true;
  getFiltersData$: Subscription = new Subscription;
  filtersObject: any = {};
  allChecked: boolean = false;
  selectedFilters: any = {};
  selectedFiltersNumber: number = 0;
  stiFilters: any[] = [];
  statusFilters: any[] = [];
  definitionFilters: any[] = [];
  sidebarUser: any;

  getSetupProcessCounter$: any;
  isLoadingCounterData: boolean = true;
  counterData: any;
  getUserDetails$: any;
  isLoadingUserDetails: boolean = false;
  translations: any;
  isFilterOpened: boolean = false;

  stiValidityTooltip: ApplicationModalMessage = {
    modalId: 'stiVal001',
    title: 'ASINDOAINSDIONOSIDNOASD',
    text: 'HEHEHEHEHEH HE HE HE HEHEHEHEHEHEHEHEHE'
  };
  stiValidityList = [
    {
      id: 'current',
      value: ''
    },
    {
      id: 'next',
      value: ''
    }
  ]
  selectedValidity: {
    id: string,
    value: string
  } | null = null;

  includeExcludeUserData: any;
  managerSerchedText: string = '';
  isLoadingManagerList: boolean = false;
  getManagerUsersList$: Subscription = new Subscription();
  managerList: User[] = [];
  downloadProcessParticipantReport$: any;
  isDownloadingAnagReport: boolean = false;
  isLoadingProfileList: boolean = false;
  getAllowedProfilesForAdmin$: Subscription = new Subscription();
  profileList: any[] = [];
  newRole: any;
  newStiValue: number = 0;


  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    private sidebarService: SidebarService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[0];
  }

  ngOnInit() {
    this.translate.get([
      'process.details.PERSON_DETAILS',
      'process.details.MANAGE_STI',
      'process.details.EXCLUDE_FROM_PROCESS',
      'process.sti.ENABLE_TITLE',
      'process.sti.ENABLE_INFO',
      'process.sti.GIVE_VALUE',
      'process.sti.GIVE_VALUE_INFO',
      'process.sti.SIGNED_DATE_TITLE',
      'process.sti.SIGNED_DATE_INFO',
      'process.sti.CONFIRM_ENABLE',
      'process.sti.CONFIRM_ENABLE_SUB',
      'process.sti.CONFIRM_ENABLE_TEXT',
      'process.manage.INCLUDE_TITLE',
      'process.manage.INCLUDE_INFO',
      'process.manage.EXCLUDE_TITLE',
      'process.manage.EXCLUDE_INFO',
      'headerDossier.processSetup.PARTICIPANT',
      'headerDossier.processSetup.OBJECTIVE_DEFINITION',
      'headerDossier.processSetup.PROCESS_PHASES',
      'process.filters.STI_ON',
      'process.filters.STI_NO',
      'process.filters.STI_OFF',
      'process.filters.EXCLUDED',
      'process.filters.ACTIVE',
      'filter.ALL',
      'filter.M',
      'filter.F',
      'calibration.filters.STRUCTURE',
      'calibration.filters.STRUCTURE_LEVEL',
      'calibration.filters.JOB',
      'calibration.filters.FUNCTION',
      'calibration.filters.STI_FILTER',
      'calibration.filters.DELETE_ALL_FILTERS',
      'calibration.filters.FILTER_ACTIVE',
      'calibration.tooltip.STRUCTURE_DESCR',
      'calibration.tooltip.STRUCTURE_LEVEL_DESCR',
      'calibration.tooltip.JOB_DESCR',
      'calibration.tooltip.FUNCTION_DESCR',
      'calibration.tooltip.STI_FILTER_DESCR',
      'calibration.tooltip.DELETE_ALL_FILTERS_DESCR',
      'calibration.tooltip.FILTER_ACTIVE_DESCR',
      'calibration.filters.GENDER',
      'calibration.tooltip.GENDER_DESCR',
      'calibration.filters.AREA',
      'calibration.tooltip.AREA_DESCR',
      'calibration.filters.STATUS',
      'calibration.tooltip.STATUS_DESCR',
      'calibration.filters.STRUCTURE_LEVEL_3',
      'calibration.tooltip.STRUCTURE_LEVEL_3_DESCR',
      'process.manage.USER_EXCLUDE_DESCR_1',
      'process.manage.USER_EXCLUDE_DESCR_2',
      'process.manage.USER_EXCLUDE_DESCR_3',
      'process.manage.USER_INCLUDE_DESCR_1',
      'process.manage.USER_INCLUDE_DESCR_2',
      'process.manage.USER_INCLUDE_DESCR_3',
      'process.sti.CURRENT_PROCESS',
      'process.sti.NEXT_PROCESS'
    ]).subscribe((translations) => {
      this.translations = translations;

      this.confirmSTIModal = {
        modalId: 'ps013',
        title: translations['process.sti.CONFIRM_ENABLE'],
        subtitle: translations['process.sti.CONFIRM_ENABLE_SUB'],
        text: translations['process.sti.CONFIRM_ENABLE_TEXT']
      }

      this.uploadIncludeTooltip = {
        modalId: 'ps014',
        title: translations['process.manage.INCLUDE_TITLE'],
        text: translations['process.manage.INCLUDE_INFO'],
      };
      this.uploadExcludeTooltip = {
        modalId: 'ps015',
        title: translations['process.manage.EXCLUDE_TITLE'],
        text: translations['process.manage.EXCLUDE_INFO'],
      };

      this.tabList = [
        {
          id: 'participant',
          name: translations['headerDossier.processSetup.PARTICIPANT']
        },
        {
          id: 'definition',
          name: translations['headerDossier.processSetup.OBJECTIVE_DEFINITION']
        },
        {
          id: 'phases',
          name: translations['headerDossier.processSetup.PROCESS_PHASES']
        }
      ]
      // tooltip filtri
      this.stiFilters = [
        {
          id: 'on',
          title: translations['process.filters.STI_ON'],
          isChecked: false
        },
        {
          id: 'off',
          title: translations['process.filters.STI_OFF'],
          isChecked: false
        },
        {
          id: 'no',
          title: translations['process.filters.STI_NO'],
          isChecked: false
        },
      ]
      this.statusFilters = [
        {
          id: 'active',
          title: translations['process.filters.ACTIVE'],
          isChecked: false
        },
        {
          id: 'excluded',
          title: translations['process.filters.EXCLUDED'],
          isChecked: false
        }
      ]
      this.whoTooltipModal = {
        modalId: "cal001",
        title: translations['calibration.tooltip.WHO'],
        text: translations['calibration.tooltip.WHO_DESCR'],
      }
      this.structureTooltipModal = {
        modalId: "cal002",
        title: translations['calibration.filters.STRUCTURE'],
        text: translations['calibration.tooltip.STRUCTURE_DESCR'],
      }
      this.structureLevel3TooltipModal = {
        modalId: "cal003",
        title: translations['calibration.filters.STRUCTURE_LEVEL_3'],
        text: translations['calibration.tooltip.STRUCTURE_LEVEL_3_DESCR'],
      }
      this.structureLevel5TooltipModal = {
        modalId: "cal003",
        title: translations['calibration.filters.STRUCTURE_LEVEL_3'],
        text: translations['calibration.tooltip.STRUCTURE_LEVEL_3_DESCR'],
      }
      this.jobTooltipModal = {
        modalId: "cal004",
        title: translations['calibration.filters.JOB'],
        text: translations['calibration.tooltip.JOB_DESCR'],
      }
      this.functionTooltipModal = {
        modalId: "cal005",
        title: translations['calibration.filters.FUNCTION'],
        text: translations['calibration.tooltip.FUNCTION_DESCR'],
      }
      this.stiFilterTooltipModal = {
        modalId: "cal006",
        title: translations['calibration.filters.STI_FILTER'],
        text: translations['calibration.tooltip.STI_FILTER_DESCR'],
      }
      this.genderFilterTooltipModal = {
        modalId: "cal006",
        title: translations['calibration.filters.GENDER'],
        text: translations['calibration.tooltip.GENDER_DESCR'],
      }
      this.areaTooltipModal = {
        modalId: "cal006",
        title: translations['calibration.filters.AREA'],
        text: translations['calibration.tooltip.AREA_DESCR'],
      }
      this.statusFilterTooltip = {
        modalId: "cal025",
        title: translations['calibration.filters.STATUS'],
        text: translations['calibration.tooltip.STATUS_DESCR'],
      }
      this.stiValidityList = [
        {
          id: 'current',
          value: translations['process.sti.CURRENT_PROCESS']
        },
        {
          id: 'next',
          value: translations['process.sti.NEXT_PROCESS']
        }
      ]
      const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
      const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
      this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
        .subscribe(
          ([loggedUser, runningPhase]) => {
            if (runningPhase && loggedUser && loggedUser.user) {
              this.loggedUser = loggedUser && loggedUser.user;
              this.route.params
                .subscribe(
                  (params: Params) => {
                    this.processYear = params.processYear;
                    this.getFilters();
                    // Recupero dettagli delle select per la sidebar
                    this.getManagerUsersList();
                    this.getAllowedProfilesForAdmin();
                    this.getSetupProcessCounter();
                    this.getParticipantList();
                  })
            }
          });
    })
  }

  // recupera il distinct dei filtri disponibili
  getFilters() {
    this.isLoadingFilters = true;

    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }

    this.getFiltersData$ = this.adminService.getDistinctFilters(parseInt(this.processYear))
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let all = { id: 'all', title: this.translations['filter.ALL'], value: null }
          let gender = [all, { id: 'M', title: this.translations['filter.M'], value: 'M' }, { id: 'F', title: this.translations['filter.F'], value: 'F' }]
          let denominazioneSO3: any = [all]
          if (data.response.denominazioneSO3 && data.response.denominazioneSO3.length) {
            for (let i = 0; i < data.response.denominazioneSO3.length; i++) {
              denominazioneSO3.push({
                id: data.response.denominazioneSO3[i],
                title: data.response.denominazioneSO3[i],
                value: data.response.denominazioneSO3[i]
              })
            }
          }
          let denominazioneSO5: any = [all]
          if (data.response.denominazioneSO5 && data.response.denominazioneSO5.length) {
            for (let i = 0; i < data.response.denominazioneSO5.length; i++) {
              denominazioneSO5.push({
                id: data.response.denominazioneSO5[i],
                title: data.response.denominazioneSO5[i],
                value: data.response.denominazioneSO5[i]
              })
            }
          }
          let funzione: any = [all]
          if (data.response.funzione && data.response.funzione.length) {
            for (let i = 0; i < data.response.funzione.length; i++) {
              funzione.push({
                id: data.response.funzione[i],
                title: data.response.funzione[i],
                value: data.response.funzione[i]
              })
            }
          }
          let mansione: any = [all]
          if (data.response.mansione && data.response.mansione.length) {
            for (let i = 0; i < data.response.mansione.length; i++) {
              mansione.push({
                id: data.response.mansione[i],
                title: data.response.mansione[i],
                value: data.response.mansione[i]
              })
            }
          }
          let area: any = [all]
          if (data.response.area && data.response.area.length) {
            for (let i = 0; i < data.response.area.length; i++) {
              area.push({
                id: data.response.area[i],
                title: data.response.area[i],
                value: data.response.area[i]
              })
            }
          }
          let structures: any = [all]
          if (data.response.structures && data.response.structures.length) {
            for (let i = 0; i < data.response.structures.length; i++) {
              structures.push({
                id: data.response.structures[i].structureId,
                title: data.response.structures[i].title,
                value: data.response.structures[i].structureId
              })
            }
          }
          this.filtersObject = {
            chiaveSesso: gender,
            denominazioneSO3: denominazioneSO3,
            denominazioneSO5: denominazioneSO5,
            funzione: funzione,
            mansione: mansione,
            area: area,
            structures: structures,
            sti: [
              all,
              {
                id: 'on',
                title: this.translations['process.filters.STI_ON'],
                value: 'WITH'
              },
              {
                id: 'off',
                title: this.translations['process.filters.STI_OFF'],
                value: 'WITHOUT'
              }
            ]
          }
          this.selectedFilters = {
            chiaveSesso: this.filtersObject.chiaveSesso[0],
            denominazioneSO3: this.filtersObject.denominazioneSO3[0],
            denominazioneSO5: this.filtersObject.denominazioneSO5[0],
            funzione: this.filtersObject.funzione[0],
            mansione: this.filtersObject.mansione[0],
            area: this.filtersObject.area[0],
            structures: this.filtersObject.structures[0],
            sti: this.filtersObject.sti[0],
          }
          this.selectedFiltersNumber = 0;
        }
        this.isLoadingFilters = false;

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c008",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFilters = false;
      });
  }

  // apre i filtri
  openFiltersSection() {
    this.isFilterOpened = !this.isFilterOpened;
  }

  // applica filtri e aggiorna lista
  applyFilters() {
    this.isFilterOpened = false;
    this.getParticipantList();
  }

  // Generico seleziona filtro
  selectFilter(data: any, id: any) {
    if (data.id == 'all') {
      this.selectedFiltersNumber--;
    }
    switch (id) {
      case 'structures':
        if (!this.selectedFilters.structures.value && this.selectedFilters.structures != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.structures = data;
        break;
      case 'denominazioneSO3':
        if (!this.selectedFilters.denominazioneSO3.value && this.selectedFilters.denominazioneSO3 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO3 = data;
        break;
      case 'denominazioneSO5':
        if (!this.selectedFilters.denominazioneSO5.value && this.selectedFilters.denominazioneSO5 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO5 = data;
        break;
      case 'mansione':
        if (!this.selectedFilters.mansione.value && this.selectedFilters.mansione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.mansione = data;
        break;
      case 'funzione':
        if (!this.selectedFilters.funzione.value && this.selectedFilters.funzione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.funzione = data;
        break;
      case 'area':
        if (!this.selectedFilters.area.value && this.selectedFilters.area != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.area = data;
        break;
      case 'sti':
        if (!this.selectedFilters.sti.value && this.selectedFilters.sti != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.sti = data;
        break;
      case 'chiaveSesso':
        if (!this.selectedFilters.chiaveSesso.value && this.selectedFilters.chiaveSesso != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.chiaveSesso = data;
        break;
      default:
        break;
    }
  }

  // Recupera i dati dei contatori
  getSetupProcessCounter() {
    this.isLoadingCounterData = true;

    if (this.getSetupProcessCounter$) {
      this.getSetupProcessCounter$.unsubscribe();
    }

    this.getSetupProcessCounter$ = this.adminService.getSetupProcessCounter(this.processYear)
      .subscribe((counterData: SenecaResponse<any>) => {
        if (counterData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "plist001",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant('errors.' + counterData.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.counterData = counterData.response;
        }
        this.isLoadingCounterData = false;
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "plist002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCounterData = false;
      })
  }

  // Ricerca testuale
  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  onSearch() {
    this.getParticipantList(true);
  }

  parseFilters() {
    let tempFilter: {
      denominazioneSO3: any,
      denominazioneSO5: any,
      funzione: any,
      mansione: any,
      area: any,
      gender: any,
      sti: any,
      structures: any,
    } = {
      denominazioneSO3: undefined,
      denominazioneSO5: undefined,
      funzione: undefined,
      mansione: undefined,
      area: undefined,
      gender: undefined,
      sti: undefined,
      structures: undefined,
    }
    if (this.selectedFilters && this.selectedFilters.denominazioneSO3 && this.selectedFilters.denominazioneSO3.value) {
      tempFilter.denominazioneSO3 = this.selectedFilters.denominazioneSO3.value
    }
    if (this.selectedFilters && this.selectedFilters.denominazioneSO5 && this.selectedFilters.denominazioneSO5.value) {
      tempFilter.denominazioneSO5 = this.selectedFilters.denominazioneSO5.value
    }
    if (this.selectedFilters && this.selectedFilters.funzione && this.selectedFilters.funzione.value) {
      tempFilter.funzione = this.selectedFilters.funzione.value
    }
    if (this.selectedFilters && this.selectedFilters.mansione && this.selectedFilters.mansione.value) {
      tempFilter.mansione = this.selectedFilters.mansione.value
    }
    if (this.selectedFilters && this.selectedFilters.area && this.selectedFilters.area.value) {
      tempFilter.area = this.selectedFilters.area.value
    }
    if (this.selectedFilters && this.selectedFilters.gender && this.selectedFilters.gender.value) {
      tempFilter.gender = this.selectedFilters.gender.value
    }
    if (this.selectedFilters && this.selectedFilters.sti && this.selectedFilters.sti.value) {
      tempFilter.sti = this.selectedFilters.sti.value
    }
    if (this.selectedFilters && this.selectedFilters.structures && this.selectedFilters.structures.value) {
      tempFilter.structures = this.selectedFilters.structures.value
    }
    return tempFilter;
  }

  // Recupera la lista utenti e altre info sul processo
  getParticipantList(resetData?: boolean) {
    this.isLoadingParticipantData = true;
    if (resetData) {
      this.resetParticipanData();
    }

    let parsedFilter = this.parseFilters();

    this.adminService.countUserInProcess(this.processYear, undefined, this.searchedText, undefined, undefined, parsedFilter.denominazioneSO3,
      parsedFilter.denominazioneSO5, parsedFilter.funzione, parsedFilter.mansione, parsedFilter.area,
      parsedFilter.gender, parsedFilter.sti, parsedFilter.structures, true)
      .subscribe((countResponse: SenecaResponse<number>) => {
        if (countResponse.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal008",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant('errors.' + countResponse.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.resetParticipanData();
          this.isLoadingParticipantData = false;
        } else {
          this.participantData.counter = countResponse.response;
          if (this.participantData.counter >= 0) {
            this.adminService.listUserInProcess(this.processYear, this.participantData.fromRecord, this.participantData.numRecords, undefined, this.searchedText, undefined, undefined,
              parsedFilter.denominazioneSO3, parsedFilter.denominazioneSO5, parsedFilter.funzione, parsedFilter.mansione,
              parsedFilter.area, parsedFilter.gender, parsedFilter.sti, parsedFilter.structures, true)
              .subscribe((userData: SenecaResponse<any>) => {
                if (userData.error) {
                  const messageObj: ApplicationModalMessage = {
                    modalId: "cal008",
                    title: this.translate.instant("generic.WARNING"),
                    text: this.translate.instant('errors.' + countResponse.error)
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                } else {
                  this.participantData.list = userData.response;
                  this.participantData.list.forEach((user: any) => {
                    if (user.profileByYear && Object.keys(user.profileByYear).includes(this.processYear) && user.profileByYear[this.processYear]) {
                      user.profileColumn = this.translate.instant('profiles.' + user.profileByYear[this.processYear]);
                    }
                    user.dataAssunzione = user.dataAssunzione ? moment(user.dataAssunzione).format("DD/MM/YYYY") : "--";
                  })
                }
                this.isLoadingParticipantData = false;
              })
          } else {
            this.participantData.list = [];
          }
        }
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "cal009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingParticipantData = false;
      })
  }

  pageChanged(page: number) {
    this.participantData.page = page;
    this.participantData.list = [];
    this.participantData.fromRecord = (page - 1) * this.participantData.numRecords;
    this.getParticipantList();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.participantData.page = 0;
    this.participantData.numRecords = item.id;
    this.participantData.list = [];
    this.participantData.fromRecord = 0;
    this.getParticipantList();
  }

  resetParticipanData() {
    this.participantData.counter = 0;
    this.participantData.fromRecord = 0;
    this.participantData.list = [];
    this.participantData.page = 1;
  }

  editProcess() {
    this.router.navigate(['admin/createUpdateProcess/' + this.processYear]);
  }

  // Includi ed escludi liste utenti
  assignFile(file: any, isInclude?: boolean) {
    this.includeFileObject = file;
  }
  assignFileExclude(file: any) {
    this.excludeFileObject = file;
  }

  openIncludePeopleModal() {
    if (!this.isProcessClosed()) {
      this.resetIncludeUpload = true;
      this.modalService.open('includePeople')
    }
  }

  closeIncludePeopleModal(confirm?: boolean) {
    this.modalService.close('includePeople');
    if (confirm) {
      this.isLoadingParticipantData = true;
      this.adminService.addParticipantToProcessByFile(this.processYear.toString(), this.includeFileObject)
        .subscribe((data: any) => {
          // this.goToPersonDetails();
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm007",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.getSetupProcessCounter();
          this.getParticipantList();
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm008",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            // this.goToPersonDetails();
            this.isLoadingParticipantData = false;
          })
    }
  }

  downloadIncludeTrack() {
    this.isDownloadingIncludeTrack = true;
    let filePath = '/assets/docs/include_users.xlsx';
    let link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
    this.isDownloadingIncludeTrack = false;
  }

  openExcludePeopleModal() {
    if (!this.isProcessClosed()) {
      this.resetExcludeUpload = true;
      this.modalService.open('excludePeople');
    }
  }

  closeExcludePeopleModal(confirm?: boolean) {
    this.modalService.close('excludePeople');
    if (confirm) {
      this.isLoadingParticipantData = true;
      this.isLoadingCounterData = true;

      this.adminService.removeParticipantFromProcessByFile(this.processYear, this.excludeFileObject)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm007",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.getSetupProcessCounter();
          this.getParticipantList();
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm008",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            // this.goToPersonDetails();
            this.isLoadingParticipantData = false;
            this.isLoadingCounterData = false;
          })
    }
  }

  downloadExcludeTrack() {
    this.isDownloadingExcludeTrack = true;
    let filePath = '/assets/docs/exclude_users.xlsx';
    let link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
    this.isDownloadingExcludeTrack = false;
  }

  // Funzioni modale filtri
  openFiltersModal() {
    this.modalService.open('filtersModal')
  }


  clearFilters() {
    this.selectedFilters = {
      chiaveSesso: this.filtersObject.chiaveSesso[0],
      denominazioneSO3: this.filtersObject.denominazioneSO3[0],
      denominazioneSO5: this.filtersObject.denominazioneSO5[0],
      area: this.filtersObject.area[0],
      funzione: this.filtersObject.funzione[0],
      mansione: this.filtersObject.mansione[0],
      structures: this.filtersObject.structures[0],
      sti: this.filtersObject.sti[0],
    }
    this.selectedFiltersNumber = 0;
    this.getParticipantList(true);
  }

  closeFiltersModal() {
    this.modalService.close("filtersModal")
  }

  toggleFilter(filterClicked: any) {
    filterClicked.isChecked = !filterClicked.isChecked;
  }

  orderBy(fieldName: string) {
  }

  // Scarica l'intera lista di utenti, inclusi o esclusi dal processo
  downloadProcessParticipantReport() {
    this.isDownloadingAnagReport = true;
    if (this.downloadProcessParticipantReport$) {
      this.downloadProcessParticipantReport$.unsubscribe();
    }

    this.downloadProcessParticipantReport$ = this.adminService.downloadProcessParticipantReport(this.processYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingAnagReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingAnagReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingAnagReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingAnagReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingAnagReport = false;
      })
  }


  toggleAnagSidebar(user: any, ignoreToggle?: boolean) {
    if (!ignoreToggle) {
      this.sidebarService.setShowNav(!this.sidebarService.isNavOpen());
    } else {
      this.getParticipantList();
    }
    // Se sto ricaricando da sidebar lo user id è dentro a user.user.userIdß
    let userId = user.userId || user.user.userId;

    this.isLoadingUserDetails = true;

    if (this.getUserDetails$) {
      this.getUserDetails$.unsubscribe();
    }

    this.getUserDetails$ = this.adminService.getUserDetailsForAdmin(this.processYear, userId)
      .subscribe((userDetail: SenecaResponse<any>) => {
        if (userDetail.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal008",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant('errors.' + userDetail.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {

          // Inizializzo dati modifica ruolo/sti
          // validità
          this.selectedValidity = this.stiValidityList[0];

          this.sidebarUser = userDetail.response;

          if (this.sidebarUser.managerUser) {
            this.sidebarUser.managerUser.title = this.sidebarUser.managerUser.forename + ' ' + this.sidebarUser.managerUser.surname;
          }

          this.sidebarUser.user.dataNascita = this.sidebarUser.user?.dataNascita ? moment(this.sidebarUser.user.dataNascita).format('DD/MM/YYYY') : '';
          this.sidebarUser.user.dataAssunzione = this.sidebarUser.user?.dataAssunzione ? moment(this.sidebarUser.user.dataAssunzione).format('DD/MM/YYYY') : '';

          // Controllo se l'utente ha un ruolo assegnato
          if (this.sidebarUser.rolesByYear && Object.keys(this.sidebarUser.rolesByYear).length && this.sidebarUser.rolesByYear[this.processYear]) {
            this.sidebarUser.roleName = this.translate.instant("profiles." + this.sidebarUser.rolesByYear[this.processYear]);
            // se ce l'ha, prevalorizzo la select
            // this.newRole = this.profileList.find(x => x.id == this.sidebarUser.rolesByYear[this.processYear]);
            if (this.sidebarUser.stiAmontByYear && Object.keys(this.sidebarUser.stiAmontByYear).length && this.sidebarUser.stiAmontByYear[this.processYear]) {
              this.sidebarUser.stiValue = this.sidebarUser.stiAmontByYear[this.processYear];
              // Se ho un sti prevalorizzo il campo della sidebar
              // this.newStiValue = JSON.parse(JSON.stringify(this.sidebarUser.stiValue));
            }

          }

          if (this.sidebarUser.hasPlannedChanges) {
            let stiValue = 0;
            if (this.sidebarUser.stiAmontByYear && Object.keys(this.sidebarUser.stiAmontByYear).length && this.sidebarUser.stiAmontByYear[(parseInt(this.processYear) + 1).toString()]) {
              stiValue = this.sidebarUser.stiAmontByYear[(parseInt(this.processYear) + 1).toString()];
            }

            this.sidebarUser.plannedChanges = {
              roleName: this.translate.instant("profiles." + this.sidebarUser.rolesByYear[(parseInt(this.processYear) + 1).toString()]),
              stiValue: stiValue
            }
          } else {
            this.sidebarUser.plannedChanges = null;
          }

          // controllo se l'utente è incluso nel processo
          if (this.sidebarUser) {
            let yearList = this.sidebarUser.includedInProcess;
            if (yearList && yearList.length) {
              let isIncludedInCurrentYear = yearList.find((process: any) => process.groupYear == this.processYear);
              this.sidebarUser.isInProcess = !!isIncludedInCurrentYear;
            } else {
              this.sidebarUser.isInProcess = false;
            }
            if (this.sidebarUser.managerUser) {
              this.sidebarUser.title = this.sidebarUser.managerUser.forename + ' ' + this.sidebarUser.managerUser.surname;
            }

          }
        }
        this.isLoadingUserDetails = false;
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "cal009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserDetails = false;
      })
  }

  // Aggiungi o togli l'utente dalla sync
  toggleUserSync() {
    this.sidebarUser.user.isInSync = !this.sidebarUser.user.isInSync;
    this.modalService.open('user-sync-modal');
  }

  closeUserSyncModal(confirm?: boolean) {
    this.modalService.close('user-sync-modal');
    if (confirm) {
      this.isLoadingUserDetails = true;
      this.adminService.setUserSyncForAdmin(this.sidebarUser.user.userId, this.sidebarUser.user.isInSync)
        .subscribe((data: SenecaResponse<any>) => {
          if (data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "ussy002",
              title: this.translate.instant("generic.WARNING"),
              text: this.translate.instant('errors.' + data.error)
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.toggleAnagSidebar(this.sidebarUser, true);
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "ussy003",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUserDetails = false;
        })
    } else {
      this.sidebarUser.user.isInSync = !this.sidebarUser.user.isInSync;
    }
  }

  // Apre il contenitore per l'edit dell'sti
  toggleEditSti() {
    this.sidebarUser.editStiOpened = !this.sidebarUser.editStiOpened;
  }


  // Setta un utente come admin (o rimuove i permessi)
  toggleIsAdmin() {
    this.sidebarUser.isAdmin = !this.sidebarUser.isAdmin;
  }

  // Setta un utente come manager Talent (o lo rimuove)
  toggleIsTalent() {
    this.sidebarUser.isTalent = !this.sidebarUser.isTalent;
  }

  stiValueChanged(newValue: number) {
    this.newStiValue = newValue;
  }

  chooseStiRole(newRole: any) {
    this.newRole = newRole;
  }

  // Apri sezione modifica manager/responsabile
  toggleEditUserManager() {
    this.sidebarUser.isEditManager = !this.sidebarUser.isEditManager;
    this.sidebarUser.originalManagerUser = JSON.parse(JSON.stringify(this.sidebarUser.managerUser));
  }

  changeUserManager(newManager: User) {
    this.sidebarUser.managerUser = newManager;
  }


  openIncludeExcludeUserModal() {
    this.includeExcludeUserData = { ...this.sidebarUser };
    if (this.sidebarUser.isInProcess) {
      this.includeExcludeUserData.modalDescr = this.translations['process.manage.USER_EXCLUDE_DESCR_1'] + this.sidebarUser.user.surname + ' ' + this.sidebarUser.user.forename +
        this.translations['process.manage.USER_EXCLUDE_DESCR_2'] + ' ' + this.processData.title + this.translations['process.manage.USER_EXCLUDE_DESCR_3'];
      this.modalService.open("includeExcludeUser");
    } else {
      this.includeExcludeUserData.modalDescr = this.translations['process.manage.USER_INCLUDE_DESCR_1'] + this.sidebarUser.user.surname + ' ' + this.sidebarUser.user.forename +
        this.translations['process.manage.USER_INCLUDE_DESCR_2'] + ' ' + this.processData.title + this.translations['process.manage.USER_INCLUDE_DESCR_3'];
      this.modalService.open("includeExcludeUser");
    }
  }

  onExcludeMotivationChanged(text: string) {
    this.includeExcludeUserData.motivation = text;
  }

  closeIncludeExcludeUser(confirm?: boolean) {
    this.modalService.close("includeExcludeUser");
    if (confirm) {
      // escludi
      if (this.includeExcludeUserData.isInProcess) {
        this.isLoadingUserDetails = true;
        this.adminService.removeUserFromProcess(this.processYear, this.sidebarUser.user.userId, this.includeExcludeUserData.motivation)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "inp001",
                title: this.translate.instant("generic.WARNING"),
                text: this.translate.instant('errors.' + data.error)
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.getSetupProcessCounter();
            this.toggleAnagSidebar(this.sidebarUser, true);
          }, (err) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "cal009",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingUserDetails = false;
          })
      } else {
        // includi
        this.isLoadingUserDetails = true;
        this.adminService.addUserToProcess(this.processYear, this.sidebarUser.user.userId)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "inp001",
                title: this.translate.instant("generic.WARNING"),
                text: this.translate.instant('errors.' + data.error)
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.getSetupProcessCounter();
            this.toggleAnagSidebar(this.sidebarUser, true);
          }, (err) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "cal009",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingUserDetails = false;
          })
      }
    }
  }

  // recupera lista di manager per il cambio 
  getManagerUsersList() {
    this.isLoadingManagerList = true;

    if (this.getManagerUsersList$) {
      this.getManagerUsersList$.unsubscribe();
    }

    this.getManagerUsersList$ = this.adminService.getManagersListForAdmin(this.processYear, this.managerSerchedText || '')
      .subscribe((data: SenecaResponse<User[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal008",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant('errors.' + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.managerList = data.response || [];
        }
        this.isLoadingManagerList = false;
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "cal009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagerList = false;
      })
  }

  // Carica la lista di profili assegnabili agli utenti
  getAllowedProfilesForAdmin() {
    this.isLoadingProfileList = true;

    if (this.getAllowedProfilesForAdmin$) {
      this.getAllowedProfilesForAdmin$.unsubscribe();
    }

    this.getAllowedProfilesForAdmin$ = this.adminService.getAllowedProfilesForAdmin()
      // Aggiungere class GetAllowedProfilesForAdminResponse
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal008",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant('errors.' + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.profileList = [];
          // formatto i  profili non sti
          if (data.response.profilesForNoSti) {
            let profiles = data.response.profilesForNoSti;
            for (let i = 0; i < profiles.length; i++) {
              this.profileList.push({
                id: profiles[i],
                title: this.translate.instant("profiles." + profiles[i]),
                isSti: false
              })
            }
          }
          // Formatto i profili sti
          if (data.response.profilesForSti) {
            let profiles = data.response.profilesForSti;
            for (let i = 0; i < profiles.length; i++) {
              this.profileList.push({
                id: profiles[i],
                title: this.translate.instant("profiles." + profiles[i]),
                isSti: true
              })
            }
          }
        }
        this.isLoadingProfileList = false;
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "cal009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingProfileList = false;
      })
  }


  onSearchTextChanged(textData: any) {
    this.managerSerchedText = textData.term;
    this.getManagerUsersList();
  }

  // Conferma cambio manager
  confirmManagerChange() {
    this.isLoadingUserDetails = true;

    this.adminService.setCustomManagerForAdmin(this.sidebarUser.user.userId, this.processYear, this.sidebarUser?.managerUser?.userId)
      .subscribe((data: SenecaResponse<User[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal008",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant('errors.' + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        // Chiudo la finestra di edit del manager
        this.sidebarUser.isEditManager = false;
        this.cancelRoleChange();
        // aggiorno i dati della sidenav
        this.toggleAnagSidebar(this.sidebarUser, true);
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "cal009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserDetails = false;
      })
  }

  cancelManagerChange() {
    this.sidebarUser.isEditManager = false;
    this.sidebarUser.managerUser = JSON.parse(JSON.stringify(this.sidebarUser.originalManagerUser));
    this.sidebarUser.originalManagerUser = null;
  }

  // cancella e chiudi edit ruolo ed sti
  cancelRoleChange() {
    this.sidebarUser.editStiOpened = false;
    this.selectedValidity = null;
    this.newRole = null;
    this.newStiValue = 0;
  }

  // Aggiorno ruolo ed sti dell'utente
  confirmRoleChange() {
    this.isLoadingUserDetails = true;
    // Modifiche per l'anno corrente o per il prossimo
    let yearForEdit = this.selectedValidity && this.selectedValidity.id != 'current' ? (parseInt(this.processYear) + 1).toString() : this.processYear;

    this.adminService.updateUserStiAndRoleForAdmin(yearForEdit, this.sidebarUser.user.userId, this.newRole.id, this.newStiValue > 0 ? this.newStiValue : null, this.selectedValidity && this.selectedValidity.id != 'current' || false)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal008",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant('errors.' + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        // Chiudo la finestra di edit del ruolo
        this.sidebarUser.editStiOpened = false;
        this.cancelRoleChange();
        // aggiorno i dati della sidenav
        this.toggleAnagSidebar(this.sidebarUser, true);
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "cal009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserDetails = false;
      })
  }

  openConfirmRoleChange() {
    this.modalService.open("confirm-role-change")
  }

  closeConfirmRoleChange(confirm?: boolean) {
    this.modalService.close("confirm-role-change");
    if (confirm) {
      this.confirmRoleChange();
    }
  }

  isConfirmRoleChangeDisabled() {
    if (!this.selectedValidity || !this.selectedValidity.id) {
      return true;
    } else {
      if (!this.newRole || !this.newRole.id) {
        return true;
      } else {
        if (this.newRole.isSti && !(this.newStiValue > 0)) {
          return true;
        }
      }
    }
    return false;
  }

  chooseStiValidity(event: any) {
    this.selectedValidity = event;
  }

  isProcessClosed() {
    if (this.processData && this.processData.statusType == "CLOSED") {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }

    if (this.getSetupProcessCounter$) {
      this.getSetupProcessCounter$.unsubscribe();
    }

    if (this.downloadProcessParticipantReport$) {
      this.downloadProcessParticipantReport$.unsubscribe();
    }
  }

} 