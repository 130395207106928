import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { SenecaResponse, GetMyManagerUserDataResponse, PerfCareMacroGoalTypes, PerfCareGoalRentStatuses, CancelUserFeedbackDialogueForUserResponse, PerfCareDevelopmentActionStatuses, PerfCareApplauseFeedback, PerfCareFeedbackDialogueTypes, DeletePrivateNoteFeedbackForUserResponse, ListPrivateNoteFeedbackForUserResponse, JwtPayload, PerfCareFinalEvaluationRentStatuses, RequiredAuth } from 'src/commonclasses';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ProfileActions from "../../core/profile/ngrx/profile.actions";
import { Observable, Subscription, of } from 'rxjs';
import { GoalUtils } from 'src/app/utils/goal.utils';
import { TabDossier } from 'src/app/utils/classes.utils';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'objective-wizard',
  templateUrl: './objectiveWizard.component.html',
  styleUrls: ['./objectiveWizard.component.scss']
})
export class ObjectiveWizardComponent implements OnInit, OnDestroy {
  personDetails: any;
  isLoadingPersonDetails = false;
  isCollaborator: boolean = false;
  routeParams$!: Subscription;
  userId: string = '';
  runningYear$: any;
  loggedUser$: any;
  runningYear: number = 0;
  getUserGoalList$: Subscription = new Subscription;
  updateUserAck$: Subscription = new Subscription;
  userAcknowledges: any;
  isLoadingUserGoals: boolean;
  goalList: any;
  getUserProfileTab$: Subscription = new Subscription;

  contentTabList!: TabDossier[];
  currentContentTab!: TabDossier;
  noDataPlaceholder: any;
  goalTitleInfo: string = "";
  canShareGoals$: Subscription = new Subscription;
  loggedUser: any;
  shareModalContent: any = {
    image: '',
    title: '',
    subtitle: '',
    text: '',
  };
  getManagerData$!: Subscription;
  isLoadingManagerInfo: boolean = true;
  managerData: any;
  getClosestInterview$!: Subscription;
  interview: any;
  isLoadingInterview: boolean = true;
  getUserInterview$: any;
  challengeList: any;
  challengeTitle: string = '';
  roleStiTitle: string = '';
  goalListTeam: any = [];
  goalListTeamForFE: any[] = [];
  challengeTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  roleGoalsTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  teamGoalsTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  commercialGoalsTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  commentDevelopPlanTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  challengeAccordionOpened: boolean = true;
  noDataImage: string = '';
  goalAccordionOpened: boolean = true;
  empowerementGoal: any;
  isFetchingDeleteInterview: boolean = false;
  deleteInterview$: Subscription = new Subscription;
  interviewIdToDelete: string = '';
  stiRulesSeen: any;
  isDownloadingRules: boolean = false;

  isFeedbackSection: boolean = false;
  noDataFeedbackPlaceholder: string = '';
  isLoadingFeedbackClaps: boolean = false;
  feedbackClapList: PerfCareApplauseFeedback[] = [];
  clapIdToDelete: string = '';
  getFeedbackClapList$: Subscription = new Subscription;
  isReceiving: boolean = true;
  feedbackUserIdToDelete: string = '';
  isObjectiveSection: boolean = false;
  isIndividualPlanSection: boolean = false;
  isLoadingDevelopmentActions: boolean = false;
  developmentActionsList: any[] = [];
  getDevelopmentActions$: Subscription = new Subscription;
  canCreateDevActions: boolean = false;
  isLoadingCanCreateDevelopmentActions: boolean = false;
  canCreateDevelopmentActions$: Subscription = new Subscription;
  isFetchingAddCommentToDevelopPlan: boolean = false;
  addCommentToDevelopPlan$: Subscription = new Subscription;
  developAction: any = {
    comment: ''
  };
  developActionModalText: string = '';
  isLoadingShareDevelopActions: boolean = false;
  shareDevelopActions$: Subscription = new Subscription;
  noDataDevelopmentActionsPlaceholder: string = '';
  developActionEdit: any;
  commentToEdit: any;
  actionForCommentDelete: any;
  commentIdToDelete: any;
  addCommentToDevelopAction$: Subscription = new Subscription;
  newActionComment: string = '';
  isLoadingDeleteDevelopAction: boolean = false;
  deleteDevelopAction$: Subscription = new Subscription;
  developActionIdToDelete: string = '';
  developActionStatusesOptions: any[] = [];
  currentActionStatus: any;
  changeActionStatus$: Subscription = new Subscription;
  isFetchingCanShareActions: boolean = false;
  canShareDevelopActions$: Subscription = new Subscription;
  canShareDevActions: boolean = false;
  isFetchingFeedbackTabs: boolean = false;
  evidencesList: any[] = [];
  isLoadingFeedbackEvidences: boolean = false;
  getFeedbackEvidencesList$: Subscription = new Subscription;
  fileTitle: string = '';
  fileObject: any;
  isToResetUploadComponent: boolean = false;
  isSmartFeedback: boolean = false;
  isEvidencesFeedback: boolean = false;
  contentFeedbackTabList!: TabDossier[];
  currentContentFeedbackTab!: TabDossier;
  isLoadingUploadFileOnEvidences: boolean = false;
  evidenceIdToManageFile: string = '';
  evidenceUploadIdToDelete: string = '';
  isLoadingDeleteFileOnEvidence: boolean = false;
  isLoadingGetDownloadUrlOnEvidence: boolean = false;
  evidenceIdOfFileToDelete: string = '';
  isLoadingGoalsToAssociate: boolean = false;
  getGoalsToAssociateToEvidence$: Subscription = new Subscription;
  canGoAddEvidence: boolean = false;
  getCounterFeedbackTabs$: Subscription = new Subscription;
  isLoadingFeedbackTabsCounters: boolean = false;
  feedbackTabCounters: any;
  isPrivateNotes: boolean = false;
  isLoadingPrivateNotes: boolean = false;
  privateNotesList: any[] = [];
  noteIdToDelete: string = '';
  // tab feedback colloqui
  isInterview: boolean = false;
  isLoadingInterviewsList: boolean = true;
  getInterviewsList$: Subscription = new Subscription;
  interviewList: any[] = [];
  getPrivateNotesList$: any;
  // Peer Feedback
  isPeerFeedback: boolean = false;
  isLoadingPeerFeedback: boolean = false;
  peerFeedbackList: any[] = [];
  peerFeedbackPlaceholder: string = '';
  peerFeedbackToDelete: string = '';
  getPeerFeedbackList$: Subscription = new Subscription;
  feedbackForRequestModal: any;
  deletePeerFeedback$: Subscription = new Subscription;
  peerSent: boolean = false;
  peerFeedbackAllowed$: Subscription = new Subscription;
  peerFeedbackAllowed: boolean = false;
  combinedSelected$: Subscription = new Subscription;
  isChief: boolean = false;
  isManager: boolean = false;
  // performance level
  perfromanceLevelActive: boolean = false;
  performanceLevelTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  }
  performanceLevelOpened: boolean = true;
  performanceLevelDone: boolean = false;
  performanceStatus: { status: string; statusMessage: any; } = {
    status: "",
    statusMessage: ""
  };
  performanceLevelEvaluation: string = "";
  getRunningPhase$: Subscription;
  runningPhase: any;
  finalEvaluationForModal: any;
  isLoadingMyEvaluation: boolean = false;
  getMyFinalEvaluation$: Subscription = new Subscription;
  finalEvaluationStatus: any;
  sharedStatuses = [
    PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_HIDDEN,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED,
  ]
  noEvaluationPost: boolean = false;
  managerFinalEvaluation: any;
  managerFinalEvaluationObservation: any;
  userFinalEvaluationDate: any;
  performanceLevelObservation: any;
  performanceLevelEvaluationDate: any;
  managerFinalEvaluationDate: any;
  // Anno selezionato per storico
  selectedYear: any;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;

  // Post calibration
  isLoadingUserCalibration: boolean = false;
  calibrationActive: boolean = false;
  postCalibrationData: {
    level: string;
    status: {
      statusMessage: string;
      status: string; // 'inProgress, completed, toStart
    };
    observations: string,
    payout: {
      id: number,
      title: string
    }
  } | any;
  translations: any;
  getPostCalibrationData$: Subscription = new Subscription;

  calibrationStatuses = [
    PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_TO_CONFIRM_EVALUATION,
    PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_CONFIRMED_IN_CALIBRATION,
    PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_CONFIRMED_BY_MANAGER,
    PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED
  ]
  isProcessClosed$: Subscription = new Subscription;
  isProcessClosed: boolean = false;
  stiAmount: number = 0;
  roleStiDescr: any;
  // definizione step
  today = new Date();
  stepList: {
    id: string,
    number: number,
    title: string,
    isComplete: boolean
  }[] = [];
  currentStep: { id: string, number: number; title: string; isComplete: boolean } | null = null;
  roleObjectiveList: any;
  roleObjectiveWeight: number = 0;
  totalWeight: number = 0;
  devObjective: any;
  feedbackMeetingDate: Date = new Date;

  isFeedbackMeetingDone: boolean = false;
  isLoadingSteps: boolean = false;
  getWizardStepList$: Subscription = new Subscription;
  confirmFeedbackMeeting$: Subscription = new Subscription;
  canProposeObjectives: boolean = false;
  cardInfoModalObject: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  objectiveForModal: any;
  deleteObjective$: Subscription = new Subscription;
  deleteId: string = '';
  getProposalStatus$: Subscription = new Subscription;
  proposalStatus: any;
  isLoadingTab: boolean = false;
  getFinalEvaluationDate$: Subscription = new Subscription;
  finalEvaluationDate: any;
  getFinalStepInfo$: Subscription = new Subscription;
  finalStepInfo: {
    developmentGoalDefinitionCompleted: boolean,
    feedbackDialoguePassed: boolean,
    goalDefinitionCompleted: boolean,
    meetingCheck: boolean,
    stiAccepted: boolean,
    feedbackDialogueDate: any
  } = {
      developmentGoalDefinitionCompleted: false,
      feedbackDialoguePassed: false,
      goalDefinitionCompleted: false,
      meetingCheck: false,
      stiAccepted: false,
      feedbackDialogueDate: null
    };
  proposeGoalsToManager$: Subscription = new Subscription;
  maxRoleTypeNumber: number = 0;
  isLoadingCascading: boolean = false;
  getCascadingGoals$: Subscription = new Subscription;
  cascadingGoal: any;
  goalSettingDeadline: any;
  isSti: boolean = false;




  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private collaboratorService: CollaboratorService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    public redirectService: RedirectService,
    private http: HttpClient) {
    this.isLoadingUserGoals = true;

    this.contentTabList = [];
    this.goalList = [];
    this.developmentActionsList = [];
    this.contentFeedbackTabList = [];

    this.peerFeedbackAllowed$ = this.store.select(fromApp.getPeerFeedbackAllowed)
      .subscribe((peerFeedbackAllowed) => {
        this.peerFeedbackAllowed = peerFeedbackAllowed;
      })

    this.getRunningPhase$ = this.store.select(fromApp.getRunningPhase).subscribe((runningPhase) => {
      this.runningPhase = runningPhase;
      this.goalSettingDeadline = this.runningPhase && this.runningPhase.phaseKey == 'GOAL_SETTING' ? this.runningPhase.deadlineDate : '--';
    })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;

      this.getFinalEvaluationDate();


      if (this.runningYear) {
        this.routeParams$ = this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.getPerformanceAssessmentYears();

              if (params.perfCareYear && params.perfCareYear.length > 4) {
                this.router.navigate(['collaborator/goalDetails/' + this.userId + '/' + this.runningYear]);
              } else {
                // Se sono in storico rimando alla pagina di dettaglio
                if (params.perfCareYear != this.runningYear && params.perfCareYear < 2023) {
                  this.router.navigate(['collaborator/personDetails/' + this.userId + '/' + params.perfCareYear]);
                } else {
                  if (params.perfCareYear) {
                    this.selectedYear = {
                      id: params.perfCareYear,
                      name: 'Anno ' + params.perfCareYear
                    }
                  } else {
                    this.selectedYear = {
                      id: this.runningYear,
                      name: 'Anno ' + this.runningYear
                    }
                  }
                }
              }

              this.checkProcessClosed();


              if (window.location.href.indexOf('feedback') > -1) {
                this.isFeedbackSection = true;
                if (window.location.href.indexOf('smartFeedback') > -1) {
                  this.isSmartFeedback = true;
                } else if (window.location.href.indexOf('evidences') > -1) {
                  this.isEvidencesFeedback = true;
                } else if (window.location.href.indexOf('privateNotes') > -1) {
                  this.isPrivateNotes = true;
                } else if (window.location.href.indexOf('interview') > -1) {
                  this.isInterview = true;
                } else if (window.location.href.indexOf('peerFeedback') > -1) {
                  this.isPeerFeedback = true;
                }
              } else if (window.location.href.indexOf('developmentPlan') > -1) {
                this.isIndividualPlanSection = true;
              } else {
                this.isObjectiveSection = true
              }
              this.isLoadingPersonDetails = true;

              // RESET dati performance level
              this.performanceLevelDone = false;
              this.perfromanceLevelActive = false;
              this.stiAmount = 0;

              const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
              const isManager$: Observable<boolean> = this.store.select(fromApp.getIsManager);
              const isChief$: Observable<boolean> = this.store.select(fromApp.getIsChief);
              this.combinedSelected$ = combineLatest([loggedUser$, isManager$, isChief$]).subscribe(
                ([loggedUser, isManager, isChief]) => {
                  this.personDetails = loggedUser && loggedUser.user;
                  this.stiAmount = this.personDetails?.stiAmount?.low || loggedUser?.params?.userStiAmountByYear?.[this.selectedYear.id] || 0;
                  this.isSti = loggedUser.auths.indexOf(RequiredAuth.PERFORMANCECARE_USER_STAFF_STI) > -1;

                  if (loggedUser && loggedUser.params && loggedUser.params.userProfilesByYear && Object.keys(loggedUser.params.userProfilesByYear).length) {
                    let auths = loggedUser.params.userProfilesByYear[this.selectedYear.id];
                    if (auths && auths.find((x: string) => x == RequiredAuth.PERFORMANCECARE_USER_OPERATIONS)) {
                      this.router.navigate(['collaboratorOperations/personDetails/' + loggedUser.user.userId + '/' + this.selectedYear.id]);
                    }
                  }

                  this.isManager = isManager;
                  this.isChief = isChief;
                  this.store.select(fromApp.getUserAcknowledges)
                    .subscribe((userAck: any) => {
                      this.userAcknowledges = userAck;
                      this.stiRulesSeen = userAck && userAck.data && !!userAck.data['STI_RULE_WATCHED'];
                    })
                  this.isLoadingPersonDetails = false;
                  // Se ho l'utente recupero i dati dei pesi e numero di obiettivi
                  if (this.personDetails && this.personDetails.userId) {
                    this.noDataFeedbackPlaceholder = '<b>' + this.personDetails.forename + ' ' + this.personDetails.surname + '</b>' + this.translate.instant('feedbackSection.PAGE_SUBTITLE');
                    this.noDataPlaceholder = this.translate.instant('goal.ADD_OBJECTIVE') + '<b>' + this.personDetails.forename + ' ' + this.personDetails.surname + '</b>';

                    this.isLoadingPersonDetails = false;
                    this.perfromanceLevelActive = false;
                    this.performanceLevelDone = false;

                    this.getUserInterview()

                    this.getManagerData();
                    this.getUserProfileTab();

                  }
                });
            }
          );
      }
    });

    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser && loggedUser.user;
      }
    })
  }

  ngOnInit() {
    this.translate.get(
      [
        'accordion.CHALLENGE',
        "accordion.GUIDELINES_STI",
        "modals.CHALLENGE.TITLE",
        "modals.CHALLENGE.SUBTITLE",
        "modals.CHALLENGE.TEXT",
        "modals.ROLE.TITLE",
        "modals.ROLE.SUBTITLE",
        "modals.ROLE.TEXT",
        "developmentPlan.PAGE_SUBTITLE_COLLABORATOR",
        "developmentPlan.statuses.toStart",
        "developmentPlan.statuses.inProgress",
        "developmentPlan.statuses.completed",
        "performance.ACCORDION_TITLE",
        "performance.ACCORDION_TOOLTIP",
        "performance.statuses.TO_DEFINE",
        "performance.statuses.TO_CONFIRM",
        'performance.statuses.COMPLETED',
        'goal.STI_RULE_CONTENT_1',
        'goal.STI_RULE_CONTENT_2',
        'objectiveWizard.STEP_1',
        'objectiveWizard.STEP_2',
        'objectiveWizard.STEP_3',
        'objectiveWizard.STEP_4',
        // descrizioni step
        "objectiveWizard.1.PROPOSE_OBJECTIVE_DESCR_1",
        "objectiveWizard.1.PROPOSE_OBJECTIVE_DESCR_2",
        'objectiveWizard.1.ACCORDION_TITLE',
        "objectiveWizard.1.WEIGHT",
        "objectiveWizard.1.TO_COMPLETE",
        'objectiveWizard.1.modal.W_ERR_TITLE',
        'objectiveWizard.1.modal.W_ERR_SUBTITLE',
        'objectiveWizard.1.modal.W_ERR_DESCR',
        "objectiveWizard.2.PROPOSE_DESCR_1",
        "objectiveWizard.2.PROPOSE_DESCR_2",
        "objectiveWizard.2.PROPOSE_DONE",
        'objectiveWizard.1.modal.W_ERR_TITLE',
        'objectiveWizard.1.modal.W_ERR_SUBTITLE',
        'objectiveWizard.1.modal.W_ERR_DESCR',
        'objectiveWizard.3.ACCORDION_DESCR',
        'objectiveWizard.4.ACCORDION_DESCR',
        "objectiveWizard.cardModal.TEXT_ROLE",
        "objectiveWizard.cardModal.WEIGHT",
        "objectiveWizard.cardModal.MAX",
        "objectiveWizard.cardModal.TEXT_DEV",
        "objectiveWizard.cardModal.TEXT_PE",
        "objectiveWizard.cardModal.TEXT_COM",
        "objectiveWizard.1.DENIED_TEXT",
        "objectiveWizard.cardModal.TEXT_TEAM",
        "objectiveWizard.cardModal.TEXT_STRUCTURE"
      ])
      .subscribe(translations => {
        this.translations = translations;

        this.roleStiTitle = translations['accordion.GUIDELINES_STI'];
        this.roleStiDescr = translations['goal.STI_RULE_CONTENT_1'] + this.selectedYear.id + translations['goal.STI_RULE_CONTENT_2'];
        this.challengeTitle = translations['accordion.CHALLENGE'] + ' ' + this.runningYear;
        this.challengeTooltipModal = {
          modalId: "154",
          title: translations["modals.CHALLENGE.TITLE"] + this.runningYear,
          subtitle: translations["modals.CHALLENGE.SUBTITLE"],
          text: translations["modals.CHALLENGE.TEXT"]
        };
        this.roleGoalsTooltipModal = {
          modalId: "168",
          title: translations["modals.ROLE.TITLE"],
          text: translations["modals.ROLE.TEXT"]
        };
        this.teamGoalsTooltipModal = {
          modalId: "500",
          title: 'Lorem Ipsum',
          subtitle: 'Lorem Ipsum',
          text: translations["modals.ROLE.TEXT"]
        };
        this.commercialGoalsTooltipModal = {
          modalId: "501",
          title: 'Lorem Ipsum',
          subtitle: 'Lorem Ipsum',
          text: translations["modals.ROLE.TEXT"]
        };
        this.commentDevelopPlanTooltipModal = {
          modalId: "d027",
          title: 'Commento azione di sviluppo',
          subtitle: 'Lorem Ipsum',
          text: 'Lorem Ipsum'
        };
        this.performanceLevelTooltip = {
          modalId: "pl001",
          title: translations["performance.ACCORDION_TITLE"],
          text: translations["performance.ACCORDION_TOOLTIP"]
        };
        this.performanceStatus = {
          status: "toStart",
          statusMessage: translations["performance.statuses.TO_DEFINE"]
        }

        this.developActionStatusesOptions = [
          {
            id: PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_START,
            title: translations["developmentPlan.statuses.toStart"]
          },
          {
            id: PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_STARTED,
            title: translations["developmentPlan.statuses.inProgress"]
          },
          {
            id: PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_COMPLETED,
            title: translations["developmentPlan.statuses.completed"]
          }
        ]
        this.noDataDevelopmentActionsPlaceholder = translations['developmentPlan.PAGE_SUBTITLE_COLLABORATOR'];
      })
  };

  getFinalEvaluationDate() {
    if (this.getFinalEvaluationDate$) {
      this.getFinalEvaluationDate$.unsubscribe();
    }
    this.collaboratorService.getFinalEvaluationDate(this.selectedYear?.id || this.runningYear)
      .subscribe((data) => {
        this.finalEvaluationDate = data.response?.finalEvaluationUserPhaseDates?.startDate;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "fevda001",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // Quando cambi bottone nell'header dossier
  changeButtonHeaderDossier(id: string) {
    switch (id) {
      case 'objectives':
        if (this.selectedYear.id < 2023 || this.proposalStatus.status == 'APPROVED') {
          this.redirectService.goToPersonDetailsCollaborator(this.userId, false, false, this.selectedYear.id);
        } else {
          this.router.navigate(['/collaborator/objectiveWizard/', this.userId, this.selectedYear.id]);
        }
        break;
      case 'feedback':
        this.redirectService.goToPersonDetailsFeedbackCollaborator(this.userId, false, this.selectedYear.id);
        break;
      case 'individualPlan':
        this.redirectService.goToPersonDetailsDevelopmentPlanCollaborator(this.userId, false, this.selectedYear.id);
        break;
    }
  }

  // Recupera i dati del manager
  getManagerData() {
    this.isLoadingManagerInfo = true;
    if (this.getManagerData$) {
      this.getManagerData$.unsubscribe();
    }
    this.getManagerData$ = this.collaboratorService.getManagerByUserId()
      .subscribe((managerData: SenecaResponse<GetMyManagerUserDataResponse>) => {
        if (managerData && managerData.error) {
          if (!this.isManager) {
            const messageObj: ApplicationModalMessage = {
              modalId: "057",
              text: this.translate.instant("errors." + managerData.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.isChief = true;
            this.store.dispatch(ProfileActions.SetIsChief({ payload: true }));
            this.isLoadingManagerInfo = false;
          }
        } else {
          this.managerData = managerData.response;
          this.noDataPlaceholder = this.translate.instant('goal.PROPOSE_TO_1') + '<b>' + this.managerData.forename + ' ' + this.managerData.surname + '</b>' + this.translate.instant('goal.PROPOSE_TO_2_CHALLENGE');
          this.noDataImage = this.personDetails.chiaveSesso === 'F' ? '/assets/img/section/share-goal-female.svg' : '/assets/img/section/share-goal-male.svg';
        }
        this.isLoadingManagerInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagerInfo = false;
      });
  }

  // Organizza colloquio
  goToInterview(id?: string) {
    if (!id) {
      this.router.navigate(['./collaborator/organizeInterview/' + this.userId]);
    } else {
      this.router.navigate(['./collaborator/organizeInterview/' + this.userId + '/' + id]);
    }
  }



  // Ritorna le tab con numero massimo obiettivi e peso
  getUserProfileTab() {
    this.isLoadingTab = true;
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }

    this.getUserProfileTab$ = this.collaboratorService.getObjectiveWeightsByProfile(this.selectedYear.id)
      .subscribe((tabList: SenecaResponse<any>) => {
        if (tabList.error || !tabList.response) {
          this.getUserProfileTab$.unsubscribe();
          if (window.location.href.indexOf('personDetails') > -1) {
            this.redirectService.goToErrorPage(tabList && tabList.error);
          }
        } else {
          this.contentTabList = []
          let isFirst = true;
          let haveSTI = false;

          for (let tab of tabList.response) {
            if (!!tab.noSti && tab.noSti) {
              haveSTI = false;
            } else {
              haveSTI = true;
            }
            let title = this.translate.instant('goal.tabs.' + tab.tabName);
            let weight = 0;
            for (let i = 0; i < tab.goalTypes.length; i++) {
              if (tab.goalTypes[i].goalType == PerfCareMacroGoalTypes.INDIVIDUAL) {
                this.totalWeight = tab.goalTypes[i].weight;
              }
              weight += tab.goalTypes[i].weight;
            }
            title += ' ' + weight + '%';
            let goalTypes = this.getGoalTypes(tab.goalTypes);
            this.contentTabList.push({
              id: tab.tabName,
              title: title,
              attrAriaLabel: title,
              goalTypes: goalTypes,
              noSti: tab.noSti,
              isDisabled: isFirst ? false : true
            })
            isFirst = false;
          }
          if (haveSTI) {
            this.contentTabList.push({
              id: 'STI',
              title: this.translate.instant('goal.tabs.STI'),
              attrAriaLabel: this.translate.instant('goal.tabs.STI'),
              isDisabled: true
            })
          }
          this.currentContentTab = this.contentTabList[0];
          if (this.currentContentTab && this.currentContentTab.goalTypes && this.currentContentTab.goalTypes.length) {
            this.currentContentTab.goalTypes = this.currentContentTab.goalTypes.filter((x: any) => {
              return x.type != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT;
            })
          }

          let modalText = "";
          // TODO aggiornare scheda con valori corretti per profilo
          for (let i = 0; i < tabList.response.length; i++) {
            for (let j = 0; j < tabList.response[i]?.goalTypes.length; j++) {
              if (tabList.response[i].goalTypes[j].goalType == PerfCareMacroGoalTypes.INDIVIDUAL) {
                modalText += this.translations['objectiveWizard.cardModal.TEXT_ROLE'];
                modalText += this.translations['objectiveWizard.cardModal.WEIGHT'] + " " + tabList.response[i].goalTypes[j].weight + " ";
                if (tabList.response[i].goalTypes[j].maxNumberOfGoals) {
                  this.maxRoleTypeNumber = tabList.response[i].goalTypes[j].maxNumberOfGoals;
                  modalText += this.translations['objectiveWizard.cardModal.MAX'] + " " + tabList.response[i].goalTypes[j].maxNumberOfGoals;
                }
                modalText += '<br><br>';
                modalText += this.translations['objectiveWizard.cardModal.TEXT_DEV'];
                modalText += '<br><br>';
              } else if (tabList.response[i].goalTypes[j].goalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
                modalText += this.translations['objectiveWizard.cardModal.TEXT_PE'];
                modalText += '<br><br>';
              } else if (tabList.response[i].goalTypes[j].goalType == PerfCareMacroGoalTypes.COMPANY) {
                modalText += this.translations['objectiveWizard.cardModal.TEXT_COM'];
                modalText += this.translations['objectiveWizard.cardModal.WEIGHT'] + " " + tabList.response[i].goalTypes[j].weight + " ";
                modalText += this.translations['objectiveWizard.cardModal.MAX'] + " " + tabList.response[i].goalTypes[j].maxNumberOfGoals;
                modalText += '<br><br>';
              } else if (tabList.response[i].goalTypes[j].goalType == PerfCareMacroGoalTypes.TEAM) {
                modalText += this.translations['objectiveWizard.cardModal.TEXT_TEAM'];
                modalText += this.translations['objectiveWizard.cardModal.WEIGHT'] + " " + tabList.response[i].goalTypes[j].weight + " ";
                modalText += this.translations['objectiveWizard.cardModal.MAX'] + " " + tabList.response[i].goalTypes[j].maxNumberOfGoals;
                modalText += '<br><br>';
              } else if (tabList.response[i].goalTypes[j].goalType == PerfCareMacroGoalTypes.STRUCTURE) {
                modalText += this.translations['objectiveWizard.cardModal.TEXT_STRUCTURE'];
                modalText += this.translations['objectiveWizard.cardModal.WEIGHT'] + " " + tabList.response[i].goalTypes[j].weight + " ";
                modalText += this.translations['objectiveWizard.cardModal.MAX'] + " " + tabList.response[i].goalTypes[j].maxNumberOfGoals;
                modalText += '<br><br>';
              }
            }
          }

          // Modale scheda utente (sti, sti manager, retail ecc);
          this.cardInfoModalObject = {
            modalId: "objw004",
            title: this.translate.instant("objectiveWizard.cardModal.TITLE"),
            subtitle: this.translate.instant("objectiveWizard.cardModal.SUBTITLE"),
            text: modalText,
          }

          this.getWizardStepList();
        }
        this.isLoadingTab = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "061",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
      });
  }



  // Formatta i goalType delle tab
  getGoalTypes(goalTypes: any[]) {
    let tmpMessages = [];
    for (let i = 0; i < goalTypes.length; i++) {
      let accordionTitle = '';
      if (goalTypes[i].goalType == PerfCareMacroGoalTypes.TEAM || goalTypes[i].goalType == PerfCareMacroGoalTypes.COMMERCIAL) {
        if (goalTypes[i].maxNumberOfGoals) {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '% - ' + this.translate.instant("goal.accordionTitles.MAX") + goalTypes[i].maxNumberOfGoals + ' a ' + this.translate.instant("goalSetting.updatePMGoal." + goalTypes[i].consuntivazione);
        } else {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '% ' + 'a ' + this.translate.instant("goalSetting.updatePMGoal." + goalTypes[i].consuntivazione);
        }
      } else {
        if (goalTypes[i].maxNumberOfGoals) {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '% - ' + this.translate.instant("goal.accordionTitles.MAX") + goalTypes[i].maxNumberOfGoals;
        } else {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '%';
        }
      }
      if (goalTypes[i].weight && goalTypes[i].maxNumberOfGoals) {
        tmpMessages.push({
          type: goalTypes[i].goalType,
          accordionTitle: accordionTitle,
          message: this.translate.instant("goal.messages.WEIGHT_INFO_1") + ' ' + goalTypes[i].weight + '% ' +
            this.translate.instant("goal.messages.WEIGHT_INFO_2") + ' ' + goalTypes[i].maxNumberOfGoals + ' ' +
            this.translate.instant("goal.messages.WEIGHT_INFO_3"),
          weight: goalTypes[i].weight,
          maxNumberOfGoals: goalTypes[i].maxNumberOfGoals
        })
      } else if (!goalTypes[i].weight && goalTypes[i].maxNumberOfGoals) {
        tmpMessages.push({
          type: goalTypes[i].goalType,
          message: this.translate.instant("goal.messages.WEIGHT_DEV_1") + ' ' + goalTypes[i].maxNumberOfGoals + ' ' +
            this.translate.instant("goal.messages.WEIGHT_DEV_2"),
          weight: goalTypes[i].weight,
          maxNumberOfGoals: goalTypes[i].maxNumberOfGoals
        })
      } else if (goalTypes[i].weight && !goalTypes[i].maxNumberOfGoals) {
        tmpMessages.push({
          type: goalTypes[i].goalType,
          accordionTitle: accordionTitle,
          weight: goalTypes[i].weight
        })
      }
    }
    return tmpMessages;
  }

  // Recupera il colloquio dell'utente
  getUserInterview() {
    if (this.getUserInterview$) {
      this.getUserInterview$.unsubscribe();
    }
    this.getUserInterview$ = this.collaboratorService.getClosestInterview(this.selectedYear.id.toString())
      // ListUserFeedbackDialogueForManager aggiungere commonclasses appena c'è
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "065",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.interview = data.response;
          this.isLoadingInterview = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "066",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingInterview = false;
      }
      );
  }

  closeShareModal() {
    this.modalService.close("confirmShareModal");
  }

  closeErrorModal() {
    this.modalService.close('errorShareModal');
  }

  onGoalDetailClicked(goal: any) {
    this.router.navigate(['/collaborator/goalDetails/' + this.userId + '/' + goal.id + '/' + this.selectedYear.id]);
  }

  goalId(index: number, el: any) {
    return el.id;
  }

  // Vai a annullare il colloquio
  goToDeleteInterview(interviewId: string) {
    this.interviewIdToDelete = interviewId;
    this.openModalDeleteInterview();
  }

  // Apre la modale per annullare il colloquio
  openModalDeleteInterview() {
    this.modalService.open('deleteInterviewModal');
  }

  // Apre la modale per annullare il colloquio
  closeModalDeleteInterview() {
    this.modalService.close('deleteInterviewModal');
    this.interviewIdToDelete = '';
  }

  // Annulla il colloquio
  deleteInterview() {
    this.isFetchingDeleteInterview = true;
    let interviewIdToDelete = this.interviewIdToDelete;
    if (this.isInterview) {
      this.isLoadingInterviewsList = true;
    }
    this.closeModalDeleteInterview();
    if (this.deleteInterview$) {
      this.deleteInterview$.unsubscribe();
    }
    this.deleteInterview$ = this.collaboratorService.deleteInterview(this.selectedYear.id.toString(), interviewIdToDelete)
      .subscribe((data: SenecaResponse<CancelUserFeedbackDialogueForUserResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "065",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "602",
            text: this.translate.instant("goalSetting.organizeInterview.DELETED_INTERVIEW_CORRECTLY_TEXT"),
            title: this.translate.instant("goalSetting.organizeInterview.DELETED_INTERVIEW_CORRECTLY")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          if (this.isInterview) {
            this.getInterviewsList();
          }
        }
        this.isFetchingDeleteInterview = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "066",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isFetchingDeleteInterview = false;
      }
      );
  }


  // Controlla se il file esiste
  fileExists(url: string): Observable<boolean> {
    return this.http.get(url).pipe(
      map(() => true),
      catchError((err: HttpErrorResponse) => {
        const success: boolean = err.status.toString().startsWith('2')
        return of(success)
      })
    )
  }

  // TODO valutare cosa fare di questa funzione
  // Accetta le regole sti dell'anno in corso
  // Problema è che se non c'è il file per l'anno in corso non riesce a fare il download
  downloadRules() {
    this.isDownloadingRules = true;
    let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
    acks.data['STI_RULE_WATCHED'] = true;
    this.updateUserAck$ = this.authService.updateUserAcknowledges(acks).
      subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "044",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let filePath = '/assets/docs/sti_rules_' + this.selectedYear.id + '.pdf';
          let link = document.createElement('a');
          link.href = filePath;

          this.fileExists(filePath).subscribe((response) => {
            if (!response) {
              filePath = '/assets/docs/sti_rules.pdf';
              link.href = filePath;
            }
            link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
            link.click();
            this.stiRulesSeen = true;
            this.isDownloadingRules = false;
          })
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "066",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingRules = false;
      });
  }

  // Cambi le tabs della sezione del feedback
  onContentFeedbackTabChanged(tab: TabDossier) {
    this.currentContentFeedbackTab = tab;
    if (this.currentContentFeedbackTab.id == 'CLAP_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/smartFeedback/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'EVIDENCES_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/evidences/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'PRIVATE_NOTES_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/privateNotes/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'INTERVIEW_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/interview/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'PEER_FEEDBACK_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/peerFeedback/' + this.userId + '/' + this.selectedYear.id]);
    }
  }

  // Vai alla pagina di crezione evidenza
  goToAddEvidence() {
    if (this.canGoAddEvidence) {
      this.router.navigate(["collaborator/createUpdateEvidenceOnEvidence/" + this.userId]);
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "d152",
        title: this.translate.instant("evidences.NOT_CAN_CREATE_EVIDENCES"),
        text: this.translate.instant("evidences.NOT_CAN_CREATE_EVIDENCES_DESCRIPTION")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
  }

  // Vai alla pagina di aggiornamento evidenza
  goToEditEvidence(id: string) {
    this.router.navigate(["collaborator/createUpdateEvidenceOnEvidence/" + this.userId + '/' + id]);
  }

  assignFile(file: any) {
    this.fileObject = file;
  }

  onFileTitleChanged(newTitle: string) {
    this.fileTitle = newTitle;
  }

  // funzioni modale upload file in evidenze
  onFileUpload(id: string) {
    this.fileTitle = '';
    this.fileObject = null;
    this.isToResetUploadComponent = true;
    this.evidenceIdToManageFile = id;
    this.modalService.open('uploadFileOnEvidences');
  }

  closeUploadFileModal() {
    this.isToResetUploadComponent = false;
    this.evidenceIdToManageFile = '';
    this.modalService.close('uploadFileOnEvidences');
  }

  confirmUploadFile() {
    this.uploadFileOnEvidences();
  }

  uploadFileOnEvidences() {
    this.isLoadingUploadFileOnEvidences = true;
    this.isToResetUploadComponent = false;

    let evidenceId = this.evidenceIdToManageFile;
    this.closeUploadFileModal();

    this.collaboratorService.uploadFileForEvidence(this.selectedYear.id.toString(), evidenceId, this.fileTitle, this.fileObject)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm010",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUploadFileOnEvidences = false;
        } else {
          // TODO evidenze sospese this.getFeedbackEvidencesList();
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm011",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUploadFileOnEvidences = false;
        })
  }

  // Cancella file evidenza
  deleteFileOnEvidence(uploadId: string, evidenceId: string) {
    this.evidenceUploadIdToDelete = uploadId;
    this.evidenceIdOfFileToDelete = evidenceId;
    this.modalService.open('deleteFileModal');
  }

  // Cancella file evidenza
  closeDeleteFileModalOnEvidence(confirmDelete: boolean) {
    if (confirmDelete) {
      this.isLoadingDeleteFileOnEvidence = true;
      this.collaboratorService.deleteFileForEvidence(this.selectedYear.id.toString(), this.evidenceIdOfFileToDelete, this.evidenceUploadIdToDelete)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm014",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingDeleteFileOnEvidence = false;
          } else {
            // TODO evidenze sospese this.getFeedbackEvidencesList();
          }
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm015",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingDeleteFileOnEvidence = false;
          })
    }
    this.evidenceUploadIdToDelete = '';
    this.evidenceIdOfFileToDelete = '';
    this.modalService.close('deleteFileModal');
  }

  // Download file nell'evidenza
  downloadFileOnEvidences(file: any, feedbackId: string) {
    this.isLoadingGetDownloadUrlOnEvidence = true;

    this.collaboratorService.retrieveNewMaskedUrlForUploadForEvidence(file.uploadId, feedbackId, this.selectedYear.id.toString())
      .subscribe((data: any) => {

        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          let downloadUrl = this.authService.getMaskedUrlDownload(data.response, file.fileName);
          setTimeout(() => {
            document.location.assign(downloadUrl);
          }, 500)
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm008",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGetDownloadUrlOnEvidence = false;
        })
  }

  getPrivateNotesList() {
    this.isLoadingPrivateNotes = true;
    this.privateNotesList = [];

    if (this.getPrivateNotesList$) {
      this.getPrivateNotesList$.unsubscribe();
    }
    this.privateNotesList = [];
    this.getPrivateNotesList$ = this.collaboratorService.getPrivateNotesList(this.selectedYear.id)
      .subscribe((data: SenecaResponse<ListPrivateNoteFeedbackForUserResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          for (let i = 0; i < data.response.length; i++) {
            this.privateNotesList.push({
              noteId: data.response[i].feedbackId,
              comment: data.response[i].comment,
              motivation: data.response[i].motivation,
              formattedCreationDate: moment(data.response[i].creationDate).fromNow()
            })
          }
        }
        this.isLoadingPrivateNotes = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPrivateNotes = false;
        })
  }

  openDeleteNoteModal(id: string) {
    this.noteIdToDelete = id;
    this.modalService.open('deleteNoteModal');
  }

  closeDeleteNoteModal(confirmDelete?: boolean) {
    this.modalService.close('deleteNoteModal');
    if (confirmDelete) {
      this.isLoadingPrivateNotes = true;
      this.collaboratorService.deletePrivateNote(this.noteIdToDelete)
        .subscribe((data: SenecaResponse<DeletePrivateNoteFeedbackForUserResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn023",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn026",
              title: this.translate.instant("privateNotes.modals.NOTE_DELETED"),
              text: this.translate.instant("privateNotes.modals.NOTE_DELETED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.getPrivateNotesList();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn024",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  goToEditPrivateNote(noteId: string) {
    this.redirectService.goToCreatePrivateNote(this.personDetails.userId, noteId);
  }


  createPrivateNote() {
    this.redirectService.goToCreatePrivateNote(this.personDetails.userId);
  }

  // Tab colloqui feedback
  getInterviewsList() {
    this.isLoadingInterviewsList = true;
    if (this.getInterviewsList$) {
      this.getInterviewsList$.unsubscribe();
    }
    this.getInterviewsList$ = this.collaboratorService.getInterviewsForUser(this.selectedYear.id.toString())
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          this.interviewList = [];
          if (data.response.length) {
            this.translate.get([
              "interview.card.FROM",
              "interview.card.TO"
            ])
              .subscribe((translations) => {
                for (let i = 0; i < data.response.length; i++) {
                  let tmp = data.response[i];
                  let when = moment(tmp.startDate).format('DD/MM/YYYY') + ' ' + translations["interview.card.FROM"] + ' ' + moment(tmp.startDate).format('HH:mm') + ' ' + translations["interview.card.TO"] + ' ' + moment(tmp.endDate).format('HH:mm');
                  this.interviewList.push({
                    feedbackDialogueId: tmp.feedbackDialogueId,
                    formattedCreationDate: moment(tmp.creationDate).fromNow(),
                    status: tmp.feedbackDialogueStatus,
                    when: when,
                    where: tmp.link,
                    argument: tmp.argument,
                    user: tmp.creationUserId == this.userId ? this.personDetails : this.managerData,
                    canCancel: tmp.creationUserId == this.loggedUser.userId ? true : false,
                    isPlaceLink: tmp.type === PerfCareFeedbackDialogueTypes.ONLINE || tmp.type === PerfCareFeedbackDialogueTypes.MS_TEAMS,
                    type: tmp.type
                  })
                }
              })
          }
        }
        this.isLoadingInterviewsList = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingInterviewsList = false;
        })
  }

  // Peer feedback
  getPeerFeedbackList() {
    this.isLoadingPeerFeedback = true;
    this.peerFeedbackList = [];
    if (this.getPeerFeedbackList$) {
      this.getPeerFeedbackList$.unsubscribe();
    }

    this.getPeerFeedbackList$ = this.collaboratorService.listPeerFeedback(this.selectedYear.id, true, true)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.peerFeedbackList = [];
          if (data.response && data.response.length) {
            for (let i = 0; i < data.response.length; i++) {
              this.peerFeedbackList.push({
                ...data.response[i],
                formattedCreationDate: moment(data.response[i].creationDate).fromNow()
              });
            }
          }
        }
        this.isLoadingPeerFeedback = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPeerFeedback = false;
        })
  }

  goToPeerFeedbackAnswer(feedbackId: string) {
    this.router.navigate(['/collaborator/createUpdatePeerFeedback/response/' + this.userId + '/' + feedbackId]);
  }

  createPeerFeedback(feedbackId: string, feedback?: any) {
    if (feedback?.approverFeedbackUser?.userId == this.loggedUser.userId) {
      this.goToPeerFeedbackAnswer(feedbackId);
    } else {
      if (feedbackId) {
        this.redirectService.goToCreatePeerFeedback(this.userId, feedbackId);
      } else {
        this.redirectService.goToCreatePeerFeedback(this.userId);
      }
    }
  }

  openDeletePeerFeedbackModal(id: string) {
    this.peerFeedbackToDelete = id;
    this.modalService.open('deletePeerFeedback');
  }

  closeDeletePeerFeedbackModal(confirm?: boolean) {
    this.modalService.close('deletePeerFeedback');
    if (confirm) {
      this.isLoadingPeerFeedback = true;
      if (this.deletePeerFeedback$) {
        this.deletePeerFeedback$.unsubscribe();
      }
      this.deletePeerFeedback$ = this.collaboratorService.deletePeerFeedback(this.selectedYear.id, this.peerFeedbackToDelete)
        .subscribe(
          (data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pf021",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else if (data.response) {
              const messageObj: ApplicationModalMessage = {
                modalId: "d028",
                title: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED"),
                text: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED_DESCR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "d029",
                title: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_ERROR"),
                text: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_TEXT_ERROR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.getPeerFeedbackList();
          },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf022",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingPeerFeedback = false;
          }
        )
    }
    this.peerFeedbackToDelete = '';
  }

  openShowFeedbackRequest(feedback: any) {
    this.feedbackForRequestModal = feedback;
    this.modalService.open('modalShowRequest')
  }

  closeShowFeedbackRequest() {
    this.modalService.close('modalShowRequest')
    this.feedbackForRequestModal = null;
  }

  changePeerTab(isSent: boolean) {
    this.peerSent = isSent;
    this.getPeerFeedbackList();
  }

  definePerformanceLevel() {
    this.router.navigate(['/collaborator/definePerformanceLevel', this.selectedYear.id]);
  }

  openFinalEvaluationModal(evaluation: any, final: boolean, isUser?: boolean, isManager?: boolean) {
    this.finalEvaluationForModal = {};

    if (!isUser && !isManager) {
      this.finalEvaluationForModal = evaluation;
    } else {
      this.finalEvaluationForModal.final = true;
      if (isUser) {
        this.finalEvaluationForModal.user = this.personDetails;
        this.finalEvaluationForModal.evaluationScore = this.performanceLevelEvaluation;
        this.finalEvaluationForModal.observation = this.performanceLevelObservation;
        this.finalEvaluationForModal.creationDate = this.performanceLevelEvaluationDate;
      } else if (isManager) {
        this.finalEvaluationForModal.isManager = true;
        this.finalEvaluationForModal.user = this.loggedUser;
        this.finalEvaluationForModal.evaluationScore = this.managerFinalEvaluation;
        this.finalEvaluationForModal.observation = this.managerFinalEvaluationObservation;
        this.finalEvaluationForModal.creationDate = this.managerFinalEvaluationDate;
      }
    }
    this.modalService.open('finalEvaluationModal')
  }

  closeFinalEvaluationModal() {
    this.modalService.close('finalEvaluationModal');
    this.finalEvaluationForModal = null;
  }

  isInFinalEvaluationPhase() {
    if (this.runningPhase.phaseKey == 'FINAL_EVALUATION') {
      let startDate = moment(this.runningPhase.phaseStartDateForUser).format();
      let endDate = moment(this.runningPhase.phaseStartDateForUser).format();
      let today = moment().format();
      if (moment(today).isSameOrAfter(startDate) && moment(today).isSameOrBefore(endDate)) {
        return true;
      }
    }
    return false;
  }

  getMyFinalEvaluation() {
    this.isLoadingMyEvaluation = true;
    if (this.getMyFinalEvaluation$) {
      this.getMyFinalEvaluation$.unsubscribe();
    }
    this.getMyFinalEvaluation$ = this.collaboratorService.getMyFinalEvaluation(this.selectedYear.id)
      .subscribe((feData: SenecaResponse<any>) => {
        if (feData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + feData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.postCalibrationData = {};
          if (feData.response) {
            this.perfromanceLevelActive = true;
            this.finalEvaluationStatus = {
              statusMessage: this.translate.instant('performance.statuses.TO_COMPILE'),
              status: 'toStart'
            }
            if (!feData.response.subordinateObservationLevel && feData.response.finalEvaluationStatus == PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_TO_CONFIRM_EVALUATION) {
              this.performanceLevelDone = true;
              this.noEvaluationPost = true;
              this.finalEvaluationStatus = {
                statusMessage: this.translations['performance.statuses.TO_CONFIRM'],
                status: 'inProgress'
              }
            }
            if (feData.response.subordinateObservationLevel) {
              this.performanceLevelDone = true;
              this.performanceLevelEvaluation = feData.response.subordinateObservationLevel;
              this.performanceLevelEvaluationDate = feData.response.creationDate;
              this.performanceLevelObservation = feData.response.subordinateObservation;
              this.finalEvaluationStatus = {
                statusMessage: this.translations['performance.statuses.TO_CONFIRM'],
                status: 'inProgress'
              }
            }
            if (this.calibrationStatuses.includes(feData.response.finalEvaluationStatus)) {
              this.calibrationActive = true;
              this.postCalibrationData.isComplete = false;
              this.postCalibrationData.status = {
                statusMessage: feData.response.finalEvaluationStatus == PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED ? this.translations['performance.statuses.COMPLETED'] : this.translations['performance.statuses.TO_CONFIRM'],
                status: feData.response.finalEvaluationStatus == PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED ? 'completed' : 'inProgress'
              };
              this.postCalibrationData.observations = null;
              this.postCalibrationData.level = null;
              this.postCalibrationData.payout = null;
            }

            if (feData.response.finalEvaluationStatus == PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED) {
              this.performanceLevelDone = true; // verifica bugfix 
              this.calibrationActive = true;
              this.postCalibrationData.isComplete = true;
              this.postCalibrationData.status = {
                statusMessage: this.translations['performance.statuses.COMPLETED'],
                status: 'completed'
              };
              if (feData.response.payout >= 0) {
                this.postCalibrationData.payout = {
                  id: feData.response.payout,
                  title: feData.response.payout + '%'
                };
              }
              this.getPostCalibrationData()
            }
          }
          if (this.isProcessClosed && !this.performanceLevelDone) {
            this.perfromanceLevelActive = false;
            this.calibrationActive = false;
          }
        }
        this.getUserProfileTab();
        this.isLoadingMyEvaluation = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingMyEvaluation = false;
      });
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.collaboratorService.getPerformanceAssessmentYears(this.userId)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          let keys = data.response.map((x) => x.assessmentYear);
          keys = keys.sort();
          for (let i = (keys.length - 1); i >= 0; i--) {
            let yearData = data.response.find((x) => x.assessmentYear == keys[i])
            this.yearList.push({
              id: yearData.assessmentYear,
              name: 'Anno ' + yearData.assessmentYear,
              disabled: !yearData.isInProcess
            });
          }
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  changeSelectedYear(year: any) {
    this.selectedYear = year;
    this.roleStiDescr = this.translations['goal.STI_RULE_CONTENT_1'] + this.selectedYear.id + this.translations['goal.STI_RULE_CONTENT_2'];

    if (window.location.href.includes('/feedback/')) {
      this.changeButtonHeaderDossier('feedback');
    } else if (window.location.href.includes('/developmentPlan/')) {
      this.changeButtonHeaderDossier('individualPlan');
    } else {
      this.changeButtonHeaderDossier('objectives');
    }
  }


  isInCurrentYear() {
    return this.runningYear == this.selectedYear.id;
  }

  openPerformanceLevelModal() {
    this.modalService.open('performance-level-modal');
  }

  closerPerformanceLevelModal() {
    this.modalService.close('performance-level-modal');
  }

  getPostCalibrationData() {
    this.isLoadingUserGoals = true;
    if (this.getPostCalibrationData$) {
      this.getPostCalibrationData$.unsubscribe();
    }
    this.getPostCalibrationData$ = this.collaboratorService.getPostCalibrationData(this.selectedYear.id)
      .subscribe((feData: SenecaResponse<any>) => {
        if (feData.error || !feData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + feData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        } else {
          if (feData.response.finalEvaluation) {
            let managerCalibration = feData.response.finalEvaluation;
            this.postCalibrationData.observations = managerCalibration.managerObservation
            this.postCalibrationData.date = managerCalibration.creationDate;
          }
          if (feData.response.calibration) {
            let calibration = feData.response.calibration;
            this.postCalibrationData.level = calibration.adminObservationLevel;
          }
          // fallback percentuale payout
          if (parseInt(feData.response.calibration.payout) >= 0) {
            this.postCalibrationData.payout = {
              id: feData.response.calibration.payout,
              title: feData.response.calibration.payout + '%'
            };
          } else {
            if (parseInt(feData.response.finalEvaluation.payout) >= 0) {
              this.postCalibrationData.payout = {
                id: feData.response.finalEvaluation.payout,
                title: feData.response.finalEvaluation.payout + '%'
              };
            }
          }
          if (parseInt(feData.response.finalEvaluation.importedPayoutAmount) >= 0) {
            this.postCalibrationData.stiAmount = feData.response.finalEvaluation.importedPayoutAmount;
          }
          // se concluso senza dati
          if (this.isProcessClosed && parseInt(feData.response.finalEvaluation.importedPayoutAmount) >= 0) {
            this.performanceLevelDone = false; // verifica bugfix
            this.calibrationActive = true;
            this.postCalibrationData.isComplete = true;

            this.postCalibrationData.status = {
              statusMessage: this.translations['performance.statuses.COMPLETED'],
              status: 'completed'
            };
          }

          this.isLoadingUserGoals = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  checkProcessClosed() {
    if (this.isProcessClosed$) {
      this.isProcessClosed$.unsubscribe();
    }
    this.isProcessClosed$ = this.collaboratorService.isProcessClosed(this.selectedYear.id)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.response) {
          this.isProcessClosed = true;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pc003",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // recupero gli step dell'utente
  getWizardStepList() {
    this.isLoadingSteps = true;

    if (this.getWizardStepList$) {
      this.getWizardStepList$.unsubscribe();
    }

    this.getWizardStepList$ = this.collaboratorService.getWizardStepList(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw002",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (data.response?.steps) {
            this.stepList = [
              {
                id: "GOAL_DEFINITION",
                number: 1,
                title: this.translations['objectiveWizard.STEP_1'],
                isComplete: data.response.steps["GOAL_DEFINITION"] == 'COMPLETED'
              },
              {
                id: "DEVELOPMENT_GOAL_DEFINITION",
                number: 2,
                title: this.translations['objectiveWizard.STEP_2'],
                isComplete: data.response.steps["DEVELOPMENT_GOAL_DEFINITION"] == 'COMPLETED'
              },
              {
                id: "FEEDBACK_DIALOGUE",
                number: 3,
                title: this.translations['objectiveWizard.STEP_3'],
                isComplete: data.response.steps["FEEDBACK_DIALOGUE"] == 'COMPLETED'
              },
              {
                id: "GOAL_SHARING",
                number: 4,
                title: this.translations['objectiveWizard.STEP_4'],
                isComplete: data.response.steps["GOAL_SHARING"] == 'COMPLETED'
              }
            ]

            this.currentStep == null;
            for (let i = 0; i < this.stepList.length; i++) {
              if (!this.stepList[i].isComplete) {
                this.currentStep = this.stepList[i];
                break;
              }
            }

            if (!this.currentStep) {
              this.currentStep = this.stepList[3];
            }
          } else {
            this.stepList = [];
          }
        }

        this.getProposalStatus();
        this.isLoadingSteps = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw003",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingSteps = false;
      });
  }


  stepClicked(newStep: any) {
    this.currentStep = newStep;
    this.getCurrentStepData()
  }

  // recupera i dati dello step corrente
  getCurrentStepData() {
    if (this.currentStep && this.currentStep.id == "GOAL_DEFINITION") {
      this.getCascadingGoals();
      this.getRoleObjectiveData();
    } else if (this.currentStep && this.currentStep.id == "DEVELOPMENT_GOAL_DEFINITION") {
      this.getDevelopmentObjectiveData();
    } else if (this.currentStep && this.currentStep.id == "FEEDBACK_DIALOGUE") {
      // non serve altro, se è già stato confermato lo step ha isCompleted a true
      this.getGoalSettingFeedbackDate();
    } else if (this.currentStep && this.currentStep.id == "GOAL_SHARING") {
      this.getFinalStepInfo();
    }
  }


  getProposalStatus() {
    this.isLoadingUserGoals = true;
    if (this.getProposalStatus$) {
      this.getProposalStatus$.unsubscribe();
    }
    this.getProposalStatus$ = this.collaboratorService.getGoalSettingProposalStatus(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUserGoals = false;
        } else {
          this.proposalStatus = data.response;
          // Stati possibili
          // TO_PROPOSE
          // PROPOSED
          // APPROVED
          // REJECTED
          if (this.proposalStatus.status == 'APPROVED') {
            this.redirectService.goToPersonDetailsCollaborator((this.loggedUser && this.loggedUser.userId), false, false, this.selectedYear.id);
          } else {
            if (!window.location.href.includes('objectiveWizard')) {
              this.router.navigate(['/collaborator/objectiveWizard/', this.userId, this.selectedYear.id]);
            } else {
              this.getCurrentStepData();
            }
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  getCascadingGoals() {
    this.isLoadingCascading = true;

    if (this.getCascadingGoals$) {
      this.getCascadingGoals$.unsubscribe();
    }

    this.getCascadingGoals$ = this.collaboratorService.getCascadingGoals(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ccasc008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingTab = false;
        } else {
          this.cascadingGoal = data.response?.cascadingGoal;
          if (this.cascadingGoal && this.cascadingGoal.introductionText && this.cascadingGoal.description) {
            this.cascadingGoal.description = this.cascadingGoal.description.replace('\n', '<br>')
            this.cascadingGoal.introductionText = this.cascadingGoal.introductionText.replace('\n', '<br>')
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "ccasc009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
      });
  }

  // Lista obiettivi di ruolo
  getRoleObjectiveData() {
    this.isLoadingUserGoals = true;

    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }

    this.getUserGoalList$ = this.collaboratorService.getRoleObjectives(this.selectedYear.id, this.currentContentTab.id, [PerfCareMacroGoalTypes.INDIVIDUAL])
      .subscribe((goalList: SenecaResponse<any>) => {
        if (goalList.error || !goalList.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw008",
            text: this.translate.instant("errors." + goalList.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let tempList = GoalUtils.formatGoalCardList(this.translate, goalList.response, false, this.userId);
          this.roleObjectiveList = tempList.filter((goal: any) => goal.type == PerfCareMacroGoalTypes.INDIVIDUAL);
          this.roleObjectiveWeight = 0;
          for (let i = 0; i < this.roleObjectiveList.length; i++) {
            this.roleObjectiveWeight += this.roleObjectiveList[i].weight;
          }
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }




  // 2 - Obiettivo di sviluppo 
  getDevelopmentObjectiveData() {
    this.isLoadingUserGoals = true;

    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }

    this.getUserGoalList$ = this.collaboratorService.getDevelopmentObjectives(this.selectedYear.id)
      .subscribe((goalList: SenecaResponse<any>) => {
        if (goalList && goalList.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw010",
            text: this.translate.instant("errors." + goalList.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (goalList.response) {
            let tempList = GoalUtils.formatGoalCardList(this.translate, [goalList.response], false, this.userId);
            this.devObjective = tempList[0];
          } else {
            this.devObjective = null;
          }
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw011",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }


  // vai alla creazione della sfida
  goToCreateEditChallenge() {
    if (this.currentStep?.id == '1' && this.roleObjectiveWeight >= this.totalWeight) {
      const modalObj: ApplicationModalMessage = {
        modalId: 'objw015',
        title: this.translate.instant('objectiveWizard.1.modal.W_ERR_TITLE'),
        subtitle: this.translate.instant('objectiveWizard.1.modal.W_ERR_SUBTITLE'),
        text: this.translate.instant('objectiveWizard.1.modal.W_ERR_DESCR'),
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: modalObj }));
    } else {
      this.router.navigate(["collaborator/createUpdateChallenge/" + this.selectedYear.id]);
    }
  }


  openDeleteObjectiveModal(id: string) {
    this.deleteId = id;
    this.modalService.open('deleteObjective');
  }

  closeDeleteObjectiveModal(confirm?: boolean) {
    this.modalService.close('deleteObjective');
    if (confirm) {
      this.deleteObjective();
    }
  }


  deleteObjective() {
    this.isLoadingUserGoals = true;

    if (this.deleteObjective$) {
      this.deleteObjective$.unsubscribe();
    }

    this.deleteObjective$ = this.collaboratorService.deleteGoals(this.runningYear, [this.deleteId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUserGoals = false;
        } else {
          this.deleteId = '';
          this.getWizardStepList();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "delobj002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });

  }


  openCardInfo() {
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.cardInfoModalObject }));
  }

  getRoleAccordionTitle() {
    let str = '<b>' + this.translations['objectiveWizard.1.ACCORDION_TITLE'] + '</b>';
    str += '&nbsp;·&nbsp;';
    str += this.translations["objectiveWizard.1.WEIGHT"] + '&nbsp;<b>';
    str += this.roleObjectiveWeight + '/' + this.totalWeight + '</b> %';
    return str;
  }

  getStepDescription(stepId: string) {
    let str = "";

    switch (stepId) {
      case "GOAL_DEFINITION":
        str = this.translations["objectiveWizard.1.PROPOSE_OBJECTIVE_DESCR_1"];
        str += '<b>' + moment(this.goalSettingDeadline).format('DD.MM.yyyy') + '</b>.<br>';
        str += this.translations["objectiveWizard.1.PROPOSE_OBJECTIVE_DESCR_2"] + '<br>';
        if (this.roleObjectiveWeight > 0) {
          str += this.translations['objectiveWizard.1.TO_COMPLETE'];
        }
        return str;
      case "DEVELOPMENT_GOAL_DEFINITION":
        if (this.devObjective) {
          str = this.translations["objectiveWizard.2.PROPOSE_DONE"];
        } else {
          str = this.translations["objectiveWizard.2.PROPOSE_DESCR_1"];
          str += '<b>' + this.managerData.forename + ' ' + this.managerData.surname + '</b>';
          str += this.translations["objectiveWizard.2.PROPOSE_DESCR_2"];
        }
        return str;
      case "FEEDBACK_DIALOGUE":
        return this.translations['objectiveWizard.3.ACCORDION_DESCR'];
      case "GOAL_SHARING":
        return this.translations['objectiveWizard.4.ACCORDION_DESCR'];
      default:
        return "no descr";
    }
  }

  // crea nuovo obiettivo con check sul peso
  goToCreateEditObjective() {
    if (this.roleObjectiveWeight < this.totalWeight && ((this.maxRoleTypeNumber && this.roleObjectiveList.length < this.maxRoleTypeNumber) || !this.maxRoleTypeNumber)) {
      this.router.navigate(["collaborator/createUpdateGoal/" + this.selectedYear.id]);
    } else {
      const modalObj: ApplicationModalMessage = {
        modalId: 'weighterr001',
        title: this.translations['objectiveWizard.1.modal.W_ERR_TITLE'],
        subtitle: this.translations['objectiveWizard.1.modal.W_ERR_SUBTITLE'],
        text: this.translations['objectiveWizard.1.modal.W_ERR_DESCR'] + '<b>' + moment(this.goalSettingDeadline).format('DD/MM/yyyy') + '</b>',
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: modalObj }));
    }
  }


  // 3 - Organizza il colloquio di feedback

  getGoalSettingFeedbackDate() {
    this.isLoadingUserGoals = true;

    if (this.confirmFeedbackMeeting$) {
      this.confirmFeedbackMeeting$.unsubscribe()
    }

    this.confirmFeedbackMeeting$ = this.collaboratorService.getGoalSettingFeedbackDate(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.feedbackMeetingDate = data.response?.date;
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw013",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }


  feedbackMeetingDateChanged(newDate: Date) {
    this.feedbackMeetingDate = newDate;
  }


  confirmFeedbackMeeting() {
    this.isLoadingUserGoals = true;

    if (this.confirmFeedbackMeeting$) {
      this.confirmFeedbackMeeting$.unsubscribe()
    }

    this.confirmFeedbackMeeting$ = this.collaboratorService.setGoalSettingFeedbackDate(this.selectedYear.id, this.feedbackMeetingDate)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.getWizardStepList();
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw013",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  // Informazioni step 4 (sti, recap, condivisione)
  getFinalStepInfo() {
    this.isLoadingUserGoals = true;

    if (this.getFinalStepInfo$) {
      this.getFinalStepInfo$.unsubscribe()
    }

    this.getFinalStepInfo$ = this.collaboratorService.getGoalSettingFinalStepInfo(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.finalStepInfo.developmentGoalDefinitionCompleted = !!data.response?.developmentGoalDefinitionCompleted;
          this.finalStepInfo.feedbackDialoguePassed = !!data.response?.feedbackDialoguePassed;
          this.finalStepInfo.goalDefinitionCompleted = !!data.response?.goalDefinitionCompleted;
          this.finalStepInfo.feedbackDialogueDate = data.response?.feedbackDialogueDate;
          this.finalStepInfo.stiAccepted = !!data.response?.stiAccepted;

          this.finalStepInfo.meetingCheck = false; // TODO forse non va così, come recupero se è stato accettato altrimenti?
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw013",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  checkFeedbackMeetingDate() {
    return moment().isSameOrAfter(this.finalStepInfo.feedbackDialogueDate)
  }

  toggleFeedbackMeetingDate() {
    this.finalStepInfo.meetingCheck = !this.finalStepInfo.meetingCheck;
  }

  // Controllo se posso proporre gli obiettivi
  canShareObjectives() {
    return this.finalStepInfo.developmentGoalDefinitionCompleted &&
      this.stepList[2].isComplete && // se lo step del feedback è ok, non le info dello step finale perchè in caso di rifiuto torna a false, disabilitando la condivisione
      this.finalStepInfo.goalDefinitionCompleted &&
      this.finalStepInfo.meetingCheck && ((this.stiAmount > 0 || this.isSti) ? this.finalStepInfo.stiAccepted : true)
  }

  openStiRuleModal() {
    this.modalService.open("stiRuleModal");
  }

  closeStiRuleModal(confirm?: boolean) {
    this.modalService.close("stiRuleModal");
    if (confirm) {
      this.finalStepInfo.stiAccepted = true;
    } else {
      this.finalStepInfo.stiAccepted = false;
    }
  }

  // apri modale condivisione scheda
  openShareWizardCardModal() {
    this.modalService.open("share-wizard-card");
  }

  // chiudi modale condivsione scheda
  closeShareObjectiveModal(confirm?: boolean) {
    this.modalService.close("share-wizard-card");
    if (confirm) {
      this.proposeGoalsToManager();

    }
  }

  proposeGoalsToManager() {
    this.isLoadingUserGoals = true;

    if (this.proposeGoalsToManager$) {
      this.proposeGoalsToManager$.unsubscribe();
    }

    this.proposeGoalsToManager$ = this.collaboratorService.proposeGoalsToManager(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.redirectService.goToPersonDetailsCollaborator(this.userId, false, false, this.selectedYear.id);
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw013",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });

  }


  // vai allo step successivo
  goToNextStep(stepId: string) {
    let currentStepIndex = 0;
    for (let i = 0; i < this.stepList.length; i++) {
      if (this.stepList[i].id == stepId) {
        currentStepIndex = i;
      }
    }
    if (this.stepList[currentStepIndex + 1]) {
      this.currentStep = this.stepList[currentStepIndex + 1];
    }
    this.getCurrentStepData()
  }

  // vai allo step precedente
  goToPrevStep(stepId: string) {
    let currentStepIndex = 0;
    for (let i = 0; i < this.stepList.length; i++) {
      if (this.stepList[i].id == stepId) {
        currentStepIndex = i;
      }
    }

    if (this.stepList[currentStepIndex - 1]) {
      this.currentStep = this.stepList[currentStepIndex - 1];
    }
    this.getCurrentStepData()
  }


  onObjectiveOptionClicked(option: any, objective: any) {
    switch (option.id) {
      case 'edit':
        if (this.isChallenge()) {
          this.router.navigate(["/collaborator/createUpdateChallenge/" + this.userId + '/' + objective.id]);
        } else {
          this.router.navigate(["/collaborator/createUpdateGoal/" + this.userId + '/' + objective.id]);
        }
        break;
      case 'delete':
        this.openDeleteObjectiveModal(objective.id);
        break;
      case 'add_evidence':
        this.router.navigate(["/collaborator/createUpdateEvidence/" + this.userId + '/' + objective.id]);
        break;
      case 'link':
        console.log("todo link");// TODO Funzionalità rimandata
        break;
      case 'value':
        this.router.navigate(["/collaborator/finalEvaluation/" + this.selectedYear.id + '/' + objective.id]);
    }
  }

  // MOdale dettaglio obeittivi
  openObjectiveDetails(objective: any) {
    this.objectiveForModal = objective;
    this.modalService.open("objectiveDetails");
  }

  closeObjectiveDetails() {
    this.modalService.close("objectiveDetails");
    this.objectiveForModal = null;
  }

  isChallenge() {
    return this.currentStep?.id == 'DEVELOPMENT_GOAL_DEFINITION';
  }

  getDeniedAccordionDescr() {
    return this.managerData.forename + ' ' + this.managerData.surname + ' ' + this.translations['objectiveWizard.1.DENIED_TEXT'];
  }

  openStatusCommentModal() {
    if (this.proposalStatus.status == 'REJECTED') {
      const messageObj: ApplicationModalMessage = {
        modalId: "deny004",
        title: this.translate.instant("objectiveWizard.1.DENIED_TITLE"),
        text: this.proposalStatus.comment || '--',
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "appr004",
        title: this.translate.instant("objectiveWizard.1.APPROVED_TITLE"),
        text: this.proposalStatus.comment || '--'
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
  }

  // apre la modale degli obiettivi di cascading
  openCascadingModal() {
    this.modalService.open('cascading-modal');
  }

  closeCascadingModal() {
    this.modalService.close('cascading-modal');
  }

  formatCascadingDescription() {
    if (this.cascadingGoal && (this.cascadingGoal.introductionText || this.cascadingGoal.description)) {
      const intro = this.cascadingGoal.introductionText?.replace(/\n/g, '<br>') || '';
      const descr = this.cascadingGoal.description?.replace(/\n/g, '<br>') || '';
      return intro + '<br><br>' + descr;
    } else {
      return '-';
    }
  }

  formatDate(date: any) {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    } else {
      return '--';
    }
  }

  ngOnDestroy(): void {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.routeParams$) {
      this.routeParams$.unsubscribe();
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    if (this.canShareGoals$) {
      this.canShareGoals$.unsubscribe();
    }
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    if (this.deleteInterview$) {
      this.deleteInterview$.unsubscribe();
    }
    if (this.getDevelopmentActions$) {
      this.getDevelopmentActions$.unsubscribe();
    }
    if (this.canCreateDevelopmentActions$) {
      this.canCreateDevelopmentActions$.unsubscribe();
    }
    if (this.addCommentToDevelopPlan$) {
      this.addCommentToDevelopPlan$.unsubscribe();
    }
    if (this.shareDevelopActions$) {
      this.shareDevelopActions$.unsubscribe();
    }
    if (this.deleteDevelopAction$) {
      this.deleteDevelopAction$.unsubscribe();
    }
    if (this.changeActionStatus$) {
      this.changeActionStatus$.unsubscribe();
    }
    if (this.canShareDevelopActions$) {
      this.canShareDevelopActions$.unsubscribe();
    }
    if (this.getFeedbackEvidencesList$) {
      this.getFeedbackEvidencesList$.unsubscribe();
    }
    if (this.getGoalsToAssociateToEvidence$) {
      this.getGoalsToAssociateToEvidence$.unsubscribe();
    }
    if (this.peerFeedbackAllowed$) {
      this.peerFeedbackAllowed$.unsubscribe();
    }
    if (this.getPeerFeedbackList$) {
      this.getPeerFeedbackList$.unsubscribe();
    }
    if (this.deletePeerFeedback$) {
      this.deletePeerFeedback$.unsubscribe();
    }
    if (this.getRunningPhase$) {
      this.getRunningPhase$.unsubscribe();
    }
    if (this.getMyFinalEvaluation$) {
      this.getMyFinalEvaluation$.unsubscribe();
    }
    if (this.getWizardStepList$) {
      this.getWizardStepList$.unsubscribe();
    }
    if (this.confirmFeedbackMeeting$) {
      this.confirmFeedbackMeeting$.unsubscribe()
    }
    // Aggiunte per wizard
    if (this.getFinalEvaluationDate$) {
      this.getFinalEvaluationDate$.unsubscribe();
    }
    if (this.getWizardStepList$) {
      this.getWizardStepList$.unsubscribe();
    }
    if (this.deleteObjective$) {
      this.deleteObjective$.unsubscribe();
    }
    if (this.getProposalStatus$) {
      this.getProposalStatus$.unsubscribe();
    }
    if (this.getFinalEvaluationDate$) {
      this.getFinalEvaluationDate$.unsubscribe();
    }
    if (this.getFinalStepInfo$) {
      this.getFinalStepInfo$.unsubscribe()
    }
    if (this.getCascadingGoals$) {
      this.getCascadingGoals$.unsubscribe();
    }
  }
}