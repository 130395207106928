<!-- Evaluation row la classe disabled rimuove il colore e lascia il bordo, se la valutazione fosse a 3 valori e non a 5 allora le classi da aggiungere saranno: value0, value2, value4-->
<div class="evaluation-box" [ngClass]="customClass">
  <ng-container *ngIf="!placeholder">
    <p *ngIf="scale == 3" class="evaluation-label">{{ 'threeStepEvaluation.' + evaluation | translate }}</p>
    <p *ngIf="scale != 3" class="evaluation-label">{{ 'evaluations.' + evaluation | translate }}</p>
    <div class="evaluation-row">
      <ng-container *ngIf="!isLoadingEvaluation">
        <div class="value-wrapper value0" [ngClass]="{'disabled': 0 > evaluationNumber}"></div>
        <div class="value-wrapper value1" [ngClass]="{'disabled': 1 > evaluationNumber}"></div>
        <div class="value-wrapper value2" [ngClass]="{'disabled': 2 > evaluationNumber}"></div>
        <div class="value-wrapper value3" *ngIf="scale == 5" [ngClass]="{'disabled': 3 > evaluationNumber}"></div>
        <div class="value-wrapper value4" *ngIf="scale == 5" [ngClass]="{'disabled': 4 > evaluationNumber}"></div>
        <!--ng-container *ngFor="let index of evaluationArray">
        <div class="value-wrapper value{{index}}" [ngClass]="{'disabled': index > evaluationNumber}"></div>
      </ng-container-->
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="placeholder">
    <p class="evaluation-label placeholder" translate="generic.EVALUATION"></p>
    <div class="evaluation-row">
      <div class="value-wrapper value0 disabled"></div>
      <div class="value-wrapper value1 disabled"></div>
      <div class="value-wrapper value2 disabled"></div>
      <div class="value-wrapper value3 disabled" *ngIf="scale == 5"></div>
      <div class="value-wrapper value4 disabled" *ngIf="scale == 5"></div>
    </div>
  </ng-container>
</div>
