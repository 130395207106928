import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { PerfCareMacroGoalTypes, SenecaResponse, TagTypes } from 'src/commonclasses';
import * as fromApp from '../../ngrx/app.reducers';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { UrlService } from 'src/app/shared/services/url.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';



@Component({
  selector: 'app-admin-edit-data-details',
  templateUrl: './editDataDetails.component.html',
  styleUrls: ['./editDataDetails.component.scss']
})
export class EditDataDetailsComponent implements OnDestroy {
  runningYear$: Subscription;
  runningYear: number = 0;

  userId: string = '';
  getPersonDetails$: any;
  personDetails: any;
  isLoadingPersonDetails: boolean = true;
  getAdminPersonDetails$: any;
  // dati obiettivi e valutazioni
  isLoadingEditableGoals: boolean = false;
  editableObjectives: any;
  isLoadingEditableEvaluations: boolean = false;
  listEditableEvaluations$: Subscription = new Subscription;
  listEditableGoal$: Subscription = new Subscription;
  evaluationsObjectives: any;
  // option perle select
  getCareOptions$: any;
  isLoadingCareOptions: boolean = false;
  careOptionsCounter: number = 0;
  careOptions: any;
  evaluationOptions: { id: string; title: any; }[] = [];
  updateObjective$: any;
  maxWeight: any;
  maxNumber: any;
  updateEvaluation$: any;
  peopleEmpowerment: any;
  deleteRow$: Subscription = new Subscription;


  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    public redirectService: RedirectService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    public urlService: UrlService,
  ) {

    this.isLoadingPersonDetails = true;
    this.isLoadingEditableGoals = true;
    this.isLoadingEditableEvaluations = true;
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });

  }

  ngOnInit() {
    this.translate.get([
      "threeStepEvaluation.BEYOND_EXPECTATIONS",
      "threeStepEvaluation.ACHIEVED",
      "threeStepEvaluation.NOT_ACHIEVED",
    ]).subscribe((translations: any) => {

      this.evaluationOptions = [
        {
          id: 'BEYOND_EXPECTATIONS',
          title: translations["threeStepEvaluation.BEYOND_EXPECTATIONS"]
        },
        {
          id: 'ACHIEVED',
          title: translations["threeStepEvaluation.ACHIEVED"]
        },
        {
          id: 'NOT_ACHIEVED',
          title: translations["threeStepEvaluation.NOT_ACHIEVED"]
        },
      ]

      this.route.params.subscribe((params: Params) => {
        this.userId = params.userId;
        // Recupero i tag care
        this.getCareOptions();
        // recupero i dati dell'utente per l'header
        this.getAdminPersonDetails();
      });
    })
  }

  // Dati utenti per l'header
  getAdminPersonDetails() {
    this.isLoadingPersonDetails = true;

    if (this.getAdminPersonDetails$) {
      this.getAdminPersonDetails$.unsubscribe();
    }

    this.getAdminPersonDetails$ = this.adminService.getUserDetailForAdmin(this.runningYear.toString(), this.userId)
      .subscribe((userData: SenecaResponse<any>) => {
        if (userData && userData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: '109',
            text: this.translate.instant('errors.' + (userData.error)),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.personDetails = userData.response.user;
          let goalConfig = userData.response?.goalConfig && userData.response?.goalConfig[this.personDetails.userId];
          if (goalConfig && goalConfig.length) {
            let individual = goalConfig.find((x: any) => x.tabName == 'INDIVIDUAL_TAB');
            if (individual && individual.goalTypes && individual.goalTypes.length) {
              let weigthData = individual.goalTypes.find((x: any) => x.goalType == 'INDIVIDUAL');
              if (weigthData) {
                this.maxWeight = weigthData.weight;
                this.maxNumber = weigthData.maxNumberOfGoals;
              }
            }
          }

          // Serve ad evitare il problema dei profili che hanno limite di peso e non di obiettivi
          if (!this.maxNumber) {
            this.maxNumber = 999;
          }

          // recupero obiettivi e valutazioni
          this.listEditableGoal();
          this.listEditableEvaluations();
        }
        this.isLoadingPersonDetails = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: '111',
          text: this.translate.instant('errors.' + ((err && err.message) || err)),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPersonDetails = false;
      });
  }

  // Recupera la lista di obiettivi modificabili per l'utente
  listEditableGoal() {
    this.isLoadingEditableGoals = true;

    if (this.listEditableGoal$) {
      this.listEditableGoal$.unsubscribe();
    }

    this.listEditableGoal$ = this.adminService.listEditableGoal(this.userId, 'INDIVIDUAL_TAB')
      .subscribe((goalData: SenecaResponse<any>) => {
        if (goalData && goalData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: '112',
            text: this.translate.instant('errors.' + (goalData.error)),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          goalData.response.forEach((goal: any) => {
            goal.ID = goal.goalId;
            if (goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
              goal.isPeopleEmpowerment = true;
            }
          });
          this.editableObjectives = goalData.response.filter((goal: any) => goal.macroGoalType != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT);
          this.peopleEmpowerment = goalData.response.filter((goal: any) => goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT);
        }
        this.isLoadingEditableGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: '113',
          text: this.translate.instant('errors.' + ((err && err.message) || err)),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingEditableGoals = false;
      });
  }


  listEditableEvaluations() {
    this.isLoadingEditableEvaluations = true;

    if (this.listEditableEvaluations$) {
      this.listEditableEvaluations$.unsubscribe();
    }

    this.listEditableEvaluations$ = this.adminService.listEditableGoal(this.userId, 'INDIVIDUAL_TAB', true)
      .subscribe((evaluationData: SenecaResponse<any>) => {
        if (evaluationData && evaluationData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: '114',
            text: this.translate.instant('errors.' + (evaluationData.error)),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // Formatto i dati che mi arrivano dai servizi per il datagrid
          evaluationData.response.forEach((objective: any) => {
            objective.ID = objective.goalId;
            // servono in particolare la valutazione dell'utente, quella del manager e la sua osservazione
            // + fallback nel caso non ci fossero
            objective.selfEvaluation = '--';
            objective.managerEvaluation = '--';
            objective.managerObservation = '--';
            if (objective.goalEvaluation && objective.goalEvaluation.length) {
              objective.goalEvaluation.forEach((evaluation: any) => {
                // se lo userId della valutazione è lo stesso della pagina, è la valutazione dell'utente
                if (evaluation && evaluation.user && evaluation.user.userId == this.userId) {
                  objective.selfEvaluation = this.translate.instant('threeStepEvaluation.' + evaluation.evaluationScore);
                  objective.selfEvaluationId = evaluation.evaluationScore;
                } else {
                  // altrimenti è del manager
                  objective.managerEvaluation = this.translate.instant('threeStepEvaluation.' + evaluation.evaluationScore);
                  objective.managerEvaluationId = evaluation.evaluationScore;
                  objective.managerObservation = evaluation.observation;
                }
              })
            }
          });
          this.evaluationsObjectives = evaluationData.response;
        }
        this.isLoadingEditableEvaluations = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: '115',
          text: this.translate.instant('errors.' + ((err && err.message) || err)),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingEditableEvaluations = false;
      });
  }

  // Recupera i tag Care
  getCareOptions() {
    this.isLoadingCareOptions = true;

    if (this.getCareOptions$) {
      this.getCareOptions$.unsubscribe();
    }

    this.getCareOptions$ = this.adminService.getCareOptions('', '', true)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: '077',
            text: this.translate.instant('errors.' + data.error),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.careOptionsCounter = data.response.totalRowsCount;
          if (data.response && data.response.rows && data.response.rows.length) {
            data.response.rows.forEach((row: any) => {
              row.id = row.tagId;
            })
          }
          this.careOptions = data.response.rows;
        }
        this.isLoadingCareOptions = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: '078',
            text: this.translate.instant('errors.' + err),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCareOptions = false;
        });
  }

  checkCanAddRow() {
    let check = false;
    if (this.editableObjectives.length < this.maxNumber) {
      check = true;
    }
    return check && this.checkWeight();
  }

  // controlla se ho superato il peso massimo consentito per il profilo
  checkWeight() {
    let currentWeight = 0;
    this.editableObjectives.forEach((goal: any) => {
      currentWeight += goal.weight;
    })
    return currentWeight <= this.maxWeight;
  }


  // Salva obiettivi 
  onSaveObjectiveRow(row?: any, people?: boolean) {

    if (row && row.changes && row.changes.length) {
      this.isLoadingEditableGoals = true;
      let changed = row.changes[0];
      if (changed) {
        this.updateObjectiveData(changed.key, changed.data, people)
      }
    }
  }


  // Aggiorna dati obiettivi
  updateObjectiveData(id: string, newData: any, people?: boolean) {
    let original;
    let isNew = false;
    // controllo se è l'obiettivo di people empowerment
    if (people) {
      original = this.peopleEmpowerment.find((x: any) => x.ID == id);
    } else {
      original = this.editableObjectives.find((x: any) => x.ID == id);
    }
    // Se sto modificando un obiettivo aggiorno i dati
    if (original) {
      if (newData.title) {
        original.title = newData.title;
      }

      if (newData.description) {
        original.description = newData.description;
      }

      if (newData.deadlineDate) {
        original.deadlineDate = newData.deadlineDate;
      }
      
      if (newData.weight >= 0) {
        original.weight = newData.weight;
      }
      console.log("original.weight", original.weight)

      if (newData.careTagId) {
        original.careTag = {
          description: null,
          tagId: newData.careTagId,
          tagType: TagTypes.PERFORMANCE_CARE,
          title: this.translate.instant('careTags.' + newData.careTagId)
        };
      }
    } else {
      // altrimenti lo creo 
      isNew = true;

      original = {
        userId: this.userId,
        title: newData.title,
        description: newData.description,
        deadlineDate: newData.deadlineDate,
        macroGoalType: PerfCareMacroGoalTypes.INDIVIDUAL,
        weight: newData.weight,
        careTagId: newData.careTagId,
        careTag: {
          description: null,
          tagId: newData.careTagId,
          tagType: TagTypes.PERFORMANCE_CARE,
          title: this.translate.instant('careTags.' + newData.careTagId)
        }
      };
    }


    if (this.updateObjective$) {
      this.updateObjective$.unsubscribe();
    }

    // chiama il servizio di create o update
    let serviceToCall = this.adminService.updateGoal(this.runningYear, original);
    if (isNew) {
      serviceToCall = this.adminService.createGoal(this.runningYear, original);
    }

    this.updateObjective$ = serviceToCall.subscribe((updatedData: SenecaResponse<any>) => {
      if (updatedData && updatedData.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: '077',
          text: this.translate.instant('errors.' + updatedData.error),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingEditableGoals = false;
      } else {
        let x: any = document.getElementById("snackbar");
        if (x) {
          x.className = "show";
          setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
        }
      }
      // aggiorna la lista degli obiettivi, altrimenti potrei avere modifiche non allineate tra le griglie
      this.listEditableGoal();
      this.listEditableEvaluations();
    }, (err: string) => {
      const messageObj: ApplicationModalMessage = {
        modalId: '078',
        text: this.translate.instant('errors.' + err),
        title: this.translate.instant('generic.WARNING')
      };
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingEditableGoals = false;
    });
  }


  // Salva riga valutazione
  onSaveEvaluationRow(row?: any) {
    this.isLoadingEditableEvaluations = true;
    if (row && row.changes && row.changes.length) {
      let changed = row.changes[0];
      if (changed) {
        this.updateObjectiveEvaluationData(changed.key, changed.data)
      }
    }
  }

  // aggiorna dati valutazione
  updateObjectiveEvaluationData(id: string, newData: any) {
    let original = this.evaluationsObjectives.find((x: any) => x.ID == id);
    let userEvaluation;
    let managerEvaluation;
    let managerEvaluationComment;
    // se ha cambiato valutazione self assessment passo quella selezionata
    if (newData.selfEvaluationId) {
      userEvaluation = newData.selfEvaluationId;
    } else {
      // altrimenti cerco quella già presente (se c'è) e passo quella
      let tmpUserEvaluation = original.goalEvaluation.find((x: any) => x.user && x.user.userId == this.userId);
      if (tmpUserEvaluation && tmpUserEvaluation.evaluationScore) {
        userEvaluation = tmpUserEvaluation.evaluationScore;
      }
    }
    // se ha cambiato valutazione manager passo quella selezionata
    if (newData.managerEvaluationId) {
      managerEvaluation = newData.managerEvaluationId;
    } else {
      let tmpManagerEvaluation = original.goalEvaluation.find((x: any) => x.user && x.user.userId != this.userId);
      if (tmpManagerEvaluation && tmpManagerEvaluation.evaluationScore) {
        managerEvaluation = tmpManagerEvaluation.evaluationScore;
      }
    }

    if (newData.managerObservation) {
      managerEvaluationComment = newData.managerObservation;
    } else {
      let tmpManagerEvaluation = original.goalEvaluation.find((x: any) => x.user && x.user.userId != this.userId);
      if (tmpManagerEvaluation && tmpManagerEvaluation.observation) {
        managerEvaluationComment = tmpManagerEvaluation.observation;
      }
    }

    if (this.updateEvaluation$) {
      this.updateEvaluation$.unsubscribe();
    }
    // aggiorna i dati della valutazione
    this.updateEvaluation$ = this.adminService.updateGoalEvaluation(this.userId, id, userEvaluation, managerEvaluation, managerEvaluationComment)
      .subscribe((updatedData: SenecaResponse<any>) => {
        if (updatedData && updatedData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: '077',
            text: this.translate.instant('errors.' + updatedData.error),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingEditableEvaluations = false;
        } else {
          let x: any = document.getElementById("snackbar");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
        }
        // recupero lista aggiornata
        // in questo caso solo delle valutazioni, perchè non può modificare il titolo
        this.listEditableEvaluations();
      }, (err: string) => {
        const messageObj: ApplicationModalMessage = {
          modalId: '078',
          text: this.translate.instant('errors.' + err),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingEditableEvaluations = false;
      });
  }

  onDeleteRow(row?: any) {
    this.isLoadingEditableGoals = true;

    if (this.deleteRow$) {
      this.deleteRow$.unsubscribe();
    }

    this.deleteRow$ = this.adminService.deleteGoal(row.data.ID)
      .subscribe((updatedData: SenecaResponse<any>) => {
        if (updatedData && updatedData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: '077',
            text: this.translate.instant('errors.' + updatedData.error),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingEditableGoals = false;
        } else {
          let x: any = document.getElementById("snackbar");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
        }
        // aggiorna la lista degli obiettivi, altrimenti potrei avere modifiche non allineate tra le griglie
        this.listEditableGoal();
        this.listEditableEvaluations();
      }, (err: string) => {
        const messageObj: ApplicationModalMessage = {
          modalId: '078',
          text: this.translate.instant('errors.' + err),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingEditableGoals = false;
      });
  }

  // controlla se ho finito di caricare i dati per popolare dx-data-grid
  isLoading() {
    return this.isLoadingCareOptions;// || this.isLoadingEditableGoals || this.isLoadingEditableEvaluations;
  }

  ngOnDestroy() {
    if (this.getAdminPersonDetails$) {
      this.getAdminPersonDetails$.unsubscribe();
    }

    if (this.listEditableGoal$) {
      this.listEditableGoal$.unsubscribe();
    }

    if (this.listEditableEvaluations$) {
      this.listEditableEvaluations$.unsubscribe();
    }

    if (this.getCareOptions$) {
      this.getCareOptions$.unsubscribe();
    }

    if (this.updateObjective$) {
      this.updateObjective$.unsubscribe();
    }

    if (this.deleteRow$) {
      this.deleteRow$.unsubscribe();
    }
  }
}